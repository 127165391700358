<app-custom-header header_title="{{ title }}"></app-custom-header>

<app-client-information [approved]="true"></app-client-information>

<div
  *ngIf="increaseRequestData$ | async as REQUEST"
  class="card mx-2 py-4 px-5"
>
  <h1 class="mx-0 mb-4">{{ "COMMITTEE_REPORT" | translate }} :</h1>
  <h2 class="fw-bold mb-4">{{ "VISIT_PREPARATION" | translate }}</h2>
  <div class="row mb-4">
    <div class="col-12 col-lg-3 custom-right-border">
      <span class="bold">{{ "COMMITTEE_VISIT_DATE" | translate }}</span> :
      {{ REQUEST.actual_visit_date }}
    </div>
    <div class="col-12 col-lg-3 custom-right-border">
      <span class="bold">{{ "SHOP_PREPARATION_REPORT" | translate }}</span> :
      {{ REQUEST.shop_equipement_report }}
    </div>
    <div class="col-12 col-lg-3 custom-right-border">
      <span class="bold">{{ "OWNER_NOTES" | translate }}</span> :
      {{ REQUEST.shop_owner_notes }}
    </div>
    <div
      class="col-12 col-lg-3 custom-right-border"
      *ngIf="REQUEST?.committee_form_file"
    >
      <span class="bold">{{ "VISIT_FILE" | translate }}</span> :
      <a [href]="REQUEST?.committee_form_file" target="_blank">
        {{ "SHOW_FILE" | translate }}
      </a>
    </div>
    <div
      class="col-12 col-lg-3 custom-right-border"
      *ngIf="REQUEST?.technical_visit_report_file"
    >
      <span class="bold">{{ "TECHNICAL_VISIT_COPY" | translate }}</span> :
      <a [href]="REQUEST?.technical_visit_report_file" target="_blank">
        {{ "SHOW_FILE" | translate }}
      </a>
    </div>
  </div>

  <h2 class="fw-bold mb-4">{{ "INCREASE_QTY_TABLE" | translate }}</h2>
  <app-advancedtable
    [collectionSize]="tableDataLength"
    [headers]="tableDataHeader"
    [list]="REQUEST.products || []"
  ></app-advancedtable>
</div>

<form-approval-actions [requestStatus]="request_status" [reAssign]="true">
</form-approval-actions>
