import { Table_Actions as Actions } from "src/app/shared/components-UI/advancedtable/advanced.model";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

type dataRow = {
  request_status: number;
  request_type: string;
  form_id: number;
  id: number;
};

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private navigatableActions: string[] = [
    Actions.review_form,
    Actions.review_request,
    Actions.update_form,
    Actions.update_request,
  ];

  constructor(private router: Router) {}

  checkRequestType<A extends keyof typeof Actions>(action: A, row: dataRow) {
    if (this.navigatableActions.includes(action)) {
      this.router.navigate([]);
    }
  }
}
