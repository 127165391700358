export enum customer_category {
  DIRECT = 1,
  DISTRIBUTER = 2,
  MULTI = 3,
  BENEFICIARY = 4,
  direct = "عميل مباشر",
  distributer = "عميل موزع",
  multi = "عميل متعدد فروع",
  beneficiary = "عميل مستفيد",
}

export enum user_type {
  ENTERPRISE = "enterprise",
  COMMITTEE = "committee",
  BRANCH = "branch",
  SALES = "sales",
  ADMIN = "admin",
  FLOUR = "flour",
  FEED = "feed",
  VIEW_ONLY = "view_only",
  enterprise = "الرئيس التنفيذى",
  committee = "عضو اللجنة",
  branch = "مدير المبيعات و التوزيع",
  sales = "مدير مبيعات الفرع",
  admin = "أدمن",
  view_only = "View Only",
  flour = "عميل دقيق",
  feed = "عميل اعلاف",
}

export enum request_status_code {
  is_reassigned = 99,
  is_rejectedByCompany = 16,
  is_missingDocuments = 15,
  is_sentToSago = 14,
  is_ToEnterprise = 13,
  is_ToBranch = 11,
  is_ToSales = 10,
  is_rejectedBySago = 9,
  is_acceptedBySago = 7,
  is_postponed = 5,
  is_underOperation = 3,
  is_newRequest = 0,
}

export enum request_type {
  new_ar = "طلب جديد",
  increase_flour_ar = "زيادة حصة الدقيق",
  increase_feed_ar = "زيادة حصة الاعلاف",
  update_information_ar = "تحديث البيانات",
  transfer_ar = "تحويل بين الشركات",
  category_transfer_ar = "تحويل فئة العميل",
  temp_request_ar = "زيادة حصة مؤقتة",
  btd_ar = "تحويل مستفيد لموزع آخر",
  dtd_ar = "تحويل مباشر الى موزع او متعدد",
  change_info_ar = "تعديل بيانات",
  update_product_ar = "تعديل اصناف",
  new_en = "new",
  increase_flour_en = "increase_flour",
  increase_feed_en = "increase_feed",
  update_information_en = "update_information",
  transfer_en = "transfer",
  category_transfer_en = "category_transfer",
  temp_request_en = "temp_request",
  btd_en = "btd",
  dtd_en = "dtd",
  change_info_en = "change_info",
  update_product_en = "update_product",
}
