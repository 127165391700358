import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";

// interceptors
import { InternetInterceptor } from "../core/interceptors/internet.interceptor";
import { LoaderInterceptor } from "../core/interceptors/loader.interceptor";
import { ErrorInterceptor } from "../core/interceptors/error.interceptor";
import { AuthInterceptor } from "../core/interceptors/auth.interceptor";
import { ApiInterceptor } from "../core/interceptors/api.interceptor";

import { NotificationMessageService } from "../core/services/notification.message.service";
import { SessionStorageService } from "../core/services/session-storage.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import { SecurityService } from "../core/services/security.service";

import { NgxHijriGregorianDatepickerModule } from "ngx-hijri-gregorian-datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// angular material
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatSortModule } from "@angular/material/sort";

import { IncreaseAmountRequestApprovalComponent } from "../pages/customer/customer-increase-products-amount/increase-amount-request-approval/increase-amount-request-approval.component";
import { IncreaseAmountRequestDetailsComponent } from "../pages/customer/customer-increase-products-amount/increase-amount-request-form/increase-amount-request-details.component";
import { IncreaseAmountRequestListComponent } from "../pages/customer/customer-increase-products-amount/increase-amount-request-list/increase-amount-request-list.component";
import { JoinRequestViewComponent } from "../pages/customer/customer-join-request/join-request-view/join-request-view.component";
import { JoinFormApprovalComponent } from "../pages/customer/customer-join-form/join-form-approval/join-form-approval.component";
import { JoinFormListComponent } from "../pages/customer/customer-join-form/join-form-list/join-form-list.component";
import { ClientInformationComponent } from "./components-reusable/client-information/client-information.component";
import { CustomerSearchComponent } from "../pages/customer/customer-search/customer-search.component";
import { ClientViewComponent } from "../pages/customer/customer-data-view/client-view.component";
import { TableFilterComponent } from "./components-reusable/table-filter/table-filter.component";
import { LoadingComponent } from "./components-UI/loading/loading.component";
import {
  AdvancedTableComponent,
  NgbdSortableHeader,
} from "./components-UI/advancedtable/advanced-table.component";
import { FormApprovalActionsComponent } from "./components-reusable/form-approval-actions/form-approval-actions.component";
import { LanguagesMenuComponent } from "./components-reusable/languages-menu/languages-menu.component";
import { CustomHeaderComponent } from "./components-reusable/custom-header/custom-header.component";
import { ProductsHistoryDisplayComponent } from "./components-reusable/products-history-display/products-history-display.component";
@NgModule({
  declarations: [
    JoinRequestViewComponent,
    JoinFormListComponent,
    CustomerSearchComponent,
    ClientViewComponent,
    TableFilterComponent,
    JoinFormApprovalComponent,
    IncreaseAmountRequestListComponent,
    IncreaseAmountRequestDetailsComponent,
    IncreaseAmountRequestApprovalComponent,
    ClientInformationComponent,

    AdvancedTableComponent,
    NgbdSortableHeader,
    LoadingComponent,
    FormApprovalActionsComponent,
    LanguagesMenuComponent,
    CustomHeaderComponent,
    ProductsHistoryDisplayComponent,
  ],
  imports: [
    DragDropModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    NgSelectModule,

    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatSnackBarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTabsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSortModule,
    MatDividerModule,
    MatTooltipModule,

    NgxHijriGregorianDatepickerModule,
    NgbModule,
  ],
  exports: [
    MatDividerModule,
    ReactiveFormsModule,
    TranslateModule,
    MatMenuModule,
    RouterModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatSnackBarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    NgxHijriGregorianDatepickerModule,
    MatTableModule,
    IncreaseAmountRequestDetailsComponent,
    IncreaseAmountRequestListComponent,
    FormApprovalActionsComponent,
    ClientInformationComponent,
    JoinRequestViewComponent,
    CustomerSearchComponent,
    AdvancedTableComponent,
    JoinFormListComponent,
    TableFilterComponent,
    LoadingComponent,
    LanguagesMenuComponent,
    CustomHeaderComponent,
  ],
  providers: [
    NotificationMessageService,
    SessionStorageService,
    LocalStorageService,
    SecurityService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
})
export class SharedModule {}
