import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export { }
declare global {
  interface DateConstructor {
    parseDateToString(target: NgbDateStruct): string;
    parseStringToNgbDateStruct(target: string): NgbDateStruct;
  }
}

Date.parseDateToString = (target: NgbDateStruct): string => {
  const month = target.month.toString().length === 1 ? `0${target.month}` : target.month
  const day = target.day.toString().length === 1 ? `0${target.day}` : target.day
  let date = target.year + '-' + month + '-' + day;
  return date;
}

Date.parseStringToNgbDateStruct = (target: string): NgbDateStruct => {
  const date: NgbDateStruct = {
    year: Number(target.split("-")[0]),
    month: Number(target.split("-")[1]),
    day: Number(target.split("-")[2])
  }
  return date;
}
