<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <!-- Table -->
        <div class="table-responsive">
          <table id="basic-datatable" class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
            <thead>
              <tr>
                <th>
                  <mat-icon>format_list_numbered_rtl</mat-icon>
                </th>
                <th *ngIf="hasSelection">
                  <mat-checkbox (change)="onHeaderSelection($event, getViewedList())"
                    [checked]="headerStatus(getViewedList())" [(ngModel)]="headerCheck" color="primary"
                    class="example-margin">
                  </mat-checkbox>
                </th>
                <th class="default-cursor text-center" scope="col" *ngFor="let header of headers; let i = index"
                  [sortable]="header.key" (sort)="onSort($event)">
                  {{ header.value | translate }}
                  <a class="text-light"><i class="bx bxs-sort-alt me-2"></i></a>
                </th>
                <th *ngIf="hasRadio">
                  {{ "CHOOSE" | translate }}
                </th>
                <th *ngIf="hasAction">{{ "ACTIONS" | translate }}</th>
              </tr>
            </thead>

            <tbody *ngIf="getViewedList().length == 0; else hasItems">
              <tr>
                <td colspan="20" class="pt-5">
                  <h3>{{ "NO_DATA" | translate }} ...</h3>
                </td>
              </tr>
            </tbody>

            <ng-template #hasItems>
              <tbody class="tbody-spacing"></tbody>
              <tbody *ngFor="let item of getViewedList(); let i = index">
                <tr>
                  <td class="right-td">{{ index * pageSize + i + 1 }}</td>
                  <td *ngIf="hasSelection">
                    <mat-checkbox [checked]="lineStatus(item.id)" (change)="onLineSelection(item.id)" color="primary"
                      class="example-margin">
                    </mat-checkbox>
                  </td>
                  <td *ngFor="let header of headers; let headerIndex = index" class="text-center" [ngClass]="{
                      'right-td': headerIndex == 0,
                      'left-td': headerIndex == headers.length - 1 && !hasAction
                    }">
                    <!-- start link -->
                    <a *ngIf="header.isLink" target="_blank" routerLink="{{
                        header.url + (item.receipt_type || '') + item.id
                      }}">
                      <ngb-highlight [result]="item[header.key]" term=""></ngb-highlight>
                    </a>
                    <!-- end link -->

                    <a *ngIf="header.isAttachment" target="_blank" href="{{ item.attachment }}">
                      <ngb-highlight [result]="'ATTACH_FILE' | translate"></ngb-highlight>
                    </a>

                    <a *ngIf="header.isMessage">
                      <div class="d-flex flex-column">
                        <ngb-highlight class="text-dark" [result]="item[header.key]" term=""></ngb-highlight>
                        <span (click)="onShowMessage(item.message)" *ngIf="item.message" style="cursor: pointer"
                          class="d-flex mt-1">
                          <mat-icon class="text-warning fs-4">error</mat-icon>
                          <span class="fs-6">{{
                            "SHOW_REASON" | translate
                            }}</span>
                        </span>
                      </div>
                    </a>

                    <!-- start checkbox -->
                    <!-- <div
                      *ngIf="header.isCheckbox"
                      class="form-check form-switch switch-button"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        (click)="onAction(header.checkBoxAction, item)"
                        [(ngModel)]="item[header.key]"
                        [disabled]="header.overlay"
                      />
                    </div> -->
                    <!-- end checkbox -->

                    <ngb-highlight *ngIf="
                        !header.isLink &&
                        !header.isCheckbox &&
                        !header.isMessage &&
                        !header.isAttachment
                      " [result]="item[header.key] | translate">
                    </ngb-highlight>
                  </td>
                  <!-- radio -->
                  <td class="left-td" *ngIf="hasRadio">
                    <mat-radio-button color="primary" [id]="i" (click)="onRadioSelection(item.id)" [value]="item.id"
                      [checked]="item.id == preSelectedRadio"></mat-radio-button>
                  </td>
                  <!-- actions -->
                  <td class="left-td" *ngIf="hasAction">
                    <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button *ngFor="let action of actions" (click)="onAction(action, item)" mat-menu-item
                        class="menu-item" [class.overlay]="
                          (action == table_actions.review_form &&
                            item.request_status === 0) ||
                          (action == table_actions.update_status &&
                            userType == user_types.COMMITTEE) ||
                          (action == table_actions.change_distributer_request &&
                            item.customer_category ===
                              categories.DISTRIBUTER) ||
                          (action == table_actions.change_distributer_request &&
                            item.customer_category === categories.DIRECT) ||
                          (action == table_actions.change_distributer_request &&
                            item.customer_category === categories.MULTI) ||
                          (action == table_actions.change_category_request &&
                            item.customer_category ===
                              categories.DISTRIBUTER) ||
                          (action == table_actions.change_category_request &&
                            item.customer_category === categories.MULTI) ||
                          (action == table_actions.change_products_request &&
                            item.customer_category ===
                              categories.DISTRIBUTER) ||
                          (action == table_actions.change_products_request &&
                            item.customer_category === categories.MULTI) ||
                          (action == table_actions.increase_products_request &&
                            item.customer_category ===
                              categories.DISTRIBUTER) ||
                          (action == table_actions.increase_products_request &&
                            item.customer_category === categories.MULTI) ||
                          (action == table_actions.temp_products_request &&
                            item.customer_category === categories.BENEFICIARY)
                        ">
                        <!-- icons used -->
                        <ng-container *ngIf="action !== 'divider'">
                          <!-- 1# Request internal actions -->
                          <mat-icon class="text-secondary" *ngIf="action == table_actions.update_request">
                            file_copy
                          </mat-icon>
                          <mat-icon class="text-warning" *ngIf="
                              action == table_actions.review_request ||
                              action == table_actions.view_beneficiary_data
                            ">
                            assignment_late
                          </mat-icon>
                          <mat-icon class="text-primary" *ngIf="
                              action == table_actions.update_form ||
                              action == table_actions.update_beneficiary_data ||
                              action ==
                                table_actions.update_beneficiary_products ||
                              action == table_actions.correct_client_data
                            ">
                            assignment
                          </mat-icon>
                          <mat-icon class="text-success" *ngIf="action == table_actions.review_form">
                            assignment_turned_in
                          </mat-icon>
                          <mat-icon class="text-primary" *ngIf="
                              action == table_actions.assign_committee_member
                            ">
                            assignment_ind
                          </mat-icon>
                          <mat-icon class="text-secondary" *ngIf="action == table_actions.attachment">
                            attachment
                          </mat-icon>

                          <!-- 2# Request external (sago) actions -->
                          <mat-icon class="text-success" *ngIf="action == table_actions.resend_request">
                            call_missed_outgoing
                          </mat-icon>
                          <mat-icon class="text-info" *ngIf="action == table_actions.update_status">
                            autorenew
                          </mat-icon>
                          <mat-icon class="text-warning" *ngIf="action == table_actions.follow_up_status">
                            info
                          </mat-icon>

                          <!-- 3# User data actions -->
                          <mat-icon class="text-secondary" *ngIf="action == table_actions.update_user_data">
                            playlist_add_check
                          </mat-icon>
                          <mat-icon class="text-dark" *ngIf="action == table_actions.change_password">
                            lock
                          </mat-icon>
                          <mat-icon class="text-danger" *ngIf="action == table_actions.delete">
                            delete_forever
                          </mat-icon>
                          <mat-icon class="text-primary" *ngIf="action == table_actions.review_client">
                            person_pin
                          </mat-icon>
                          <mat-icon class="text-secondary" *ngIf="
                              action == table_actions.temp_products_request ||
                              action == table_actions.change_info_request ||
                              action == table_actions.change_products_request ||
                              action ==
                                table_actions.increase_products_request ||
                              action == table_actions.change_category_request ||
                              action ==
                                table_actions.change_distributer_request ||
                              action == table_actions.change_branch_request
                            ">
                            assignment
                          </mat-icon>
                          <mat-icon class="text-success" *ngIf="
                              action == table_actions.change_sap_number ||
                              action == table_actions.edit
                            ">
                            edit
                          </mat-icon>
                          <mat-icon class="text-secondary" *ngIf="action == table_actions.product_history">
                            history
                          </mat-icon>

                          <span>{{ action | translate }}</span>
                        </ng-container>

                        <ng-container *ngIf="action === 'divider'">
                          <mat-divider></mat-divider>
                        </ng-container>

                        <!-- item label -->
                      </button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>

        <!-- End table -->
        <div *ngIf="hasPagination" class="row justify-content-md-between align-items-md-center mt-2">
          <!-- start Pagination -->
          <div class="text-md-right float-md-end pagination-rounded">
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true"
              (page)="pageChange($event)" [length]="collectionSize" [pageSize]="pageSize">
            </mat-paginator>
          </div>

          <!-- End Pagination -->
        </div>
      </div>
    </div>
  </div>
</div>