export const LOOKUPS_API = {
  PRODUCTS_BY_ACTIVITY: "lookup/activity/product/list",
  ALL_PRODUCTS: "lookup/product/list",
  ADMIN_PRODUCTS: "lookup/products/",
  ALL_BRANCHES: "lookup/list-all-branches",
  BRANCHES: "lookup/branch/list",
  SAP_CODES: "lookup/sap-code/list",
  SAP_SUB_CODES: "lookup/sap-subcode/list",
  ACTIVITIES: "lookup/activity/list",
  FEED_ACTIVITIES: "lookup/activity/feed/list",
  CUSTOMER_TYPES: "lookup/customerType/list",
  DOCUMENT_TYPES: "lookup/documentType/list",
  AREAS: "lookup/area/list",
  CITIES: "lookup/city/list",
  CITIES_BY_AREA: "lookup/list-cities/",
  DISTRICTS: "lookup/district/list",
  DISTRICTS_BY_CITY: "lookup/list-districts/",
  DISTRIBUTERS_BY_BRANCH: "lookup/list-distributers/",
  ACTIVITY_PRODUCTS_UPDATE: "customer/approved/sago/export",
} as const;
