import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { TranslateService } from "@ngx-translate/core";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export function createTranslateLoader(
  http: HttpClient
): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "../../../assets/i18n/", suffix: ".json" },
    { prefix: "../../../assets/i18n/buttons/", suffix: ".json" },
    { prefix: "../../../assets/i18n/headers/", suffix: ".json" },
    { prefix: "../../../assets/i18n/inputs/", suffix: ".json" },
    { prefix: "../../../assets/i18n/paragraphs/", suffix: ".json" },
    { prefix: "../../../assets/i18n/validation/", suffix: ".json" },
    { prefix: "../../../assets/i18n/popup-messages/", suffix: ".json" },
  ]);
}
@Injectable({ providedIn: "root" })
export class LanguageService {
  public languages: string[] = ["en", "ar"];

  constructor(public translate: TranslateService) {
    this.translate.addLangs(this.languages);
  }

  setLanguage(lang) {
    this.translate.use(lang);
  }
}
