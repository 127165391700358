import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { customer_category as CATEGORY } from "src/app/core/utilities/shared_enums";
// #endregion
@Component({
  selector: "app-join-request-view",
  templateUrl: "./join-request-view.component.html",
  styleUrls: ["./join-request-view.component.scss"],
})
export class JoinRequestViewComponent implements OnInit {
  // #region declare variables
  hasProducts: boolean = false;
  listOfHeaderTitle: any[];
  listOfItems: any[] = [];

  title = "JOIN_REQUEST_REVIEW";

  // #endregion

  // #region constructor
  constructor(private activeRoute: ActivatedRoute, public router: Router) {
    //#region  init variables
    this.listOfHeaderTitle = [
      { key: "product", value: "PROD_CODE" },
      { key: "product_name", value: "PRODUCT_NAME" },
      { key: "asked_amount", value: "QTY_REQUIRED" },
    ];
    // #endregion
  }
  // #endregion

  // #region ngOnInit

  ngOnInit(): void {}

  // #endregion
  extractCustomerOldData({ customer_category, products }) {
    if (
      customer_category === CATEGORY.BENEFICIARY ||
      customer_category === CATEGORY.DIRECT
    ) {
      this.hasProducts = true;
      this.listOfItems = products.map((line) => {
        return {
          ...line,
          asked_amount: line.asked_packed_amount
            ? line.asked_packed_amount
            : line.asked_unpacked_amount,
        };
      });
    }
  }

  // #region main action

  getFileType(copy: string) {
    return copy.split(".").pop() == "pdf"
      ? "../../../../../../../assets/images/pdf.png"
      : copy;
  }

  print() {
    window.print();
  }

  // #endregion
}
