import { Direction } from "@angular/cdk/bidi";
import { Component } from "@angular/core";

import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { LoaderService } from "./core/services/loader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  textDir: Direction = "rtl";
  currentLang = "ar";

  // #endregion

  // #region constructor

  constructor(
    public loaderService: LoaderService,
    private translate: TranslateService
  ) {}

  // #endregion

  ngOnInit() {
    this.translate.setDefaultLang(this.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.textDir = event.lang === "ar" ? "rtl" : "ltr";
    });
  }
}
