// Angular modules
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

// Services

// Models
import { customer_category } from "src/app/pages/customer/customer.utility";
import { ResponseDto } from "src/app/core/interfaces/api-response";
import { MatAccordion } from "@angular/material/expansion";
import { Observable, Subscription } from "rxjs";
import { FilterModelDto } from "src/app/pages/company/company.utility";
import { LOOKUPS_API } from "src/app/core/apis/lookup.api";
import { ApiService } from "src/app/core/services/api.service";

@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.scss"],
})
export class TableFilterComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() isRequest: boolean;
  @Input() isActive: boolean;
  @Input() isGeneral: boolean;
  @Input() isFeed: boolean;
  @Input() hasData: Observable<boolean>;

  @Output() filterModel = new EventEmitter<object>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  // models
  model: FilterModelDto;

  // lists
  allCustomers: customer_category[];
  flourRadioGroup: any[];
  allRequestTypes: any[];
  allBranches: any[];

  subscription: Subscription;

  // #region constructor

  constructor(private API: ApiService) {
    this.model = new FilterModelDto();
    this.initControls();
  }

  // #endregion

  // #region OnInit

  ngOnInit(): void {
    this.loadControls();
    this.subscription = this.hasData.subscribe((value) => {
      value ? this.accordion.closeAll() : null;
    });
  }

  // #endregion

  // #region controls

  loadControls() {
    this.getAllBranches();
  }

  getAllBranches() {
    this.API.getDataFromServer(LOOKUPS_API.BRANCHES).subscribe(
      (response: ResponseDto) => (this.allBranches = response.data)
    );
  }

  initControls() {
    this.allCustomers = [
      { id: 1, name: "DIRECT_CLIENT" },
      { id: 2, name: "DISTRIBUTER_CLIENT" },
      { id: 3, name: "MULTI_CLIENT" },
      { id: 4, name: "BENEFICIARY_CLIENT" },
    ];
    this.flourRadioGroup = [
      { name: "NO_SORTING", value: "-id" },
      { name: "CLIENT_NAME", value: "name" },
      { name: "CITY_LICENCE", value: "city_license_number" },
      { name: "ID_NUMBER", value: "id_number" },
      { name: "BRANCH", value: "branch" },
      { name: "MOBILE", value: "mobile" },
    ];
    this.allRequestTypes = [
      { type: "new", name: "NEW_REQUESTS" },
      { type: "increase_flour", name: "INCREASE_REQ" },
      { type: "temp_request", name: "TEMP_REQ" },
      { type: "category_transfer", name: "CATEGORY_CHANGE_REQ" },
      { type: "change_info", name: "CHANGE_INFO_REQ" },
      { type: "update_product", name: "PRODUCTS_EDIT_REQ" },
      { type: "tab", name: "BRANCH_CHANGE_REQ" },
      { type: "btd", name: "DISTRIBUTER_T_DISTRIBUTER" },
      { type: "direct_to_distributor", name: "DIRECT_DISTRIBUTER" },
      { type: "direct_to_beneficiary", name: "DIRECT_BENEFICIARY" },
      { type: "beneficiary_to_direct", name: "BENEFICIARY_DIRECT" },
    ];
    this.allRequestTypes = [
      { type: "new", name: "طلب جديد" },
      { type: "increase_flour", name: "زيادة حصة" },
      { type: "temp_request", name: "تعديل موسمى للحصة" },
      { type: "category_transfer", name: "تحويل الفئة" },
      { type: "change_info", name: "تعديل بيانات" },
      { type: "update_product", name: "تعديل اصناف" },
      { type: "tab", name: "تحويل بين الفروع" },
      { type: "btd", name: "تحويل مستفيد من موزع لاخر" },
      { type: "dtd", name: "نحويل من موزع اللى متعدد" },
      { type: "direct_to_distributor", name: "تحويل من مباشر لموزع" },
      { type: "direct_to_beneficiary", name: "تحويل من مباشر لمستفيد" },
      { type: "beneficiary_to_direct", name: "تحويل من مستفيد لمباشر" },
      { type: "transfer", name: "تحويل بين الشركات" },
    ];
  }

  // #endregion

  // #region actions

  emitModelData(model: FilterModelDto) {
    this.filterModel.emit(model);
  }

  // #endregion

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
