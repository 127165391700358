<ng-container *ngIf="isVisible">
  <div
    class="mx-2 mb-3 px-5 pb-5 pt-2 row justify-content-center align-items-center"
  >
    <button
      (click)="approveCommitteeForm()"
      class="col-8 col-md-2 mx-2 btn btn-success my-1"
    >
      {{ "ACCEPT" | translate }}
    </button>
    <button
      (click)="rejectCommitteeForm()"
      class="col-8 col-md-2 mx-2 btn btn-danger my-1"
    >
      {{ "REJECT" | translate }}
    </button>
    <button
      *ngIf="reAssign"
      (click)="reAssignCommitteeForm()"
      class="col-8 col-md-2 mx-2 btn btn-primary my-1"
    >
      {{ "RE_ASSIGN" | translate }}
    </button>
    <button
      *ngIf="hasEdit.active"
      (click)="editCurrentRequest()"
      class="col-8 col-md-2 mx-2 btn btn-primary my-1"
    >
      {{ "EDIT" | translate }}
    </button>
  </div>
</ng-container>
