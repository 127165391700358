import { Component, OnInit } from "@angular/core";

import { SessionTimeoutService } from "../core/services/session-timeout.service";
import { SecurityService } from "../core/services/security.service";
import { DialogService } from "../core/services/dialog.service";

import { LAYOUT_WIDTH } from "./layouts.model";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
})
export class LayoutComponent implements OnInit {
  constructor(
    private sessionTimeout: SessionTimeoutService,
    private securityService: SecurityService,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    if (this.securityService.retrieveUser()) {
      this.securityService.getAccessToken();
      this.startActiveSession();
    }
    this.LayoutWidth(LAYOUT_WIDTH);
  }

  private LayoutWidth(width: string) {
    document.body.setAttribute("data-layout-size", width);
    document.body.classList.remove("vertical-collpsed");
    document.body.removeAttribute("data-layout-scrollable");
  }

  private startActiveSession() {
    this.sessionTimeout.sessionSubscription = this.sessionTimeout
      .startSession()
      .subscribe(() => {
        this.dialog.alertMessage("SESSION_TIMEOUT");
        this.securityService.logout();
      });
  }
}
