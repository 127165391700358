<app-advancedtable
  dir="auto"
  class="px-3"
  [headers]="listOfHeaderTitle"
  [list]="list"
  [hasRadio]="true"
  (lineChose)="onLineChose($event)"
  [hasPagination]="false"
  [preSelectedRadio]="
    model.assigned_to ? model.assigned_to : model.assigned_to_id
  "
></app-advancedtable>
<button
  (click)="sendCommittedUser()"
  mat-raised-button
  class="mx-auto d-block bg-purple"
>
  {{ "CONFIRM" | translate }}
</button>
