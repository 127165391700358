import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  Directive,
  QueryList,
  ViewChildren,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { SecurityService } from "src/app/core/services/security.service";
import { DialogService } from "src/app/core/services/dialog.service";

import { Table_Actions } from "./advanced.model";
import { request_type } from "../../../core/utilities/shared_enums";
import { customer_category } from "../../../core/utilities/shared_enums";
import { user_type } from "../../../core/utilities/shared_enums";
import { NavigationService } from "src/app/core/services/navigation.service";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "th[sortable]",
  host: {
    "[class.asc]": 'direction === "asc"',
    "[class.desc]": 'direction === "desc"',
    "(click)": "rotate()",
  },
})
export class NgbdSortableHeader {
  @Input() sortable: string = "";
  @Input() collectionSize: number = 0;
  @Input() direction: SortDirection = "";
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: "app-advancedtable",
  templateUrl: "./advanced-table.component.html",
  styleUrls: ["./advanced-table.component.scss"],
  providers: [DecimalPipe],
})
export class AdvancedTableComponent implements OnInit, OnChanges {
  // #region declare variables

  @Input() headers: { key: string; value: string }[];
  @Input() list: any[];
  @Input() hasAction: boolean;
  @Input() hasRadio: boolean;
  @Input() preSelectedRadio: number;
  @Input() hasSelection: boolean;
  @Input() hasPagination: boolean;
  @Input() collectionSize: number;
  @Input() actions: string[];

  @Output() actionCreated = new EventEmitter<{ action: string; model: any }>();
  @Output() lineChose = new EventEmitter<number>();
  @Output() linesSelected = new EventEmitter<number[]>();
  @Output() pageData = new EventEmitter<any>();

  currentpageNumber: number;
  pageSize: number = 20;
  index = 0;
  page = 1;
  headerCheck: boolean;
  selectedLines: number[] = [];

  table_actions = Table_Actions;

  categories = customer_category;
  request_types = request_type;
  user_types = user_type;

  userType: string = this.securityService.retrieveUser().user_type;

  @ViewChildren(NgbdSortableHeader) sortHeaders: QueryList<NgbdSortableHeader>;

  column: any;
  direction: SortDirection;

  // #endregion

  // #region constructor

  constructor(
    private dialogService: DialogService,
    private securityService: SecurityService,
    public navigationService: NavigationService,
    public translate: TranslateService
  ) {
    this.hasPagination = true;
  }

  // #endregion

  // #region ngOnInit

  ngOnInit() {}

  // #endregion

  // #region ngOnChanges
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      for (let index = 0; index < (this.currentpageNumber - 1) * 10; index++) {
        this.list.unshift(" ");
      }
    }
  }

  // #endregion

  // #region main action

  onShowMessage(message: string) {
    this.dialogService.alertMessage(message);
  }

  onSort({ column, direction }: SortEvent) {
    this.sortHeaders.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.column = column;
    this.direction = direction;
  }

  onAction(action: string, model: any) {
    this.actionCreated.emit({ action, model });
  }

  pageChange(event: any) {
    this.pageSize = event.pageSize;
    this.index = event.pageIndex;
    event.pageIndex++;
    this.pageData.emit(event);
  }

  onLineSelection(id: number) {
    if (!this.selectedLines.includes(id)) {
      this.selectedLines.push(id);
    } else
      this.selectedLines = this.selectedLines.filter((line) => line !== id);
    this.linesSelected.emit(this.selectedLines);
  }

  onRadioSelection(id: number) {
    this.lineChose.emit(id);
  }

  onHeaderSelection(status: any, allData: any) {
    if (status.checked) {
      this.selectedLines = [];
      this.headerCheck = false;
      setTimeout(() => {
        this.headerCheck = true;
        allData.forEach((line: any) => this.onLineSelection(line.id));
        this.linesSelected.emit(this.selectedLines);
      });
    } else this.selectedLines = [];
    this.linesSelected.emit(this.selectedLines);
  }

  lineStatus(line_id: number) {
    if (this.selectedLines.find((id) => id == line_id)) return true;
    else return false;
  }

  headerStatus(list: any[]) {
    const matched_lines = list.filter((element) =>
      this.selectedLines.includes(element.id)
    );
    if (matched_lines.length == this.pageSize) return true;
    else return false;
  }

  getViewedList() {
    // with sort
    if (this.column && this.direction)
      return this.list
        .sort((a, b) => {
          const res = compare(a[this.column], b[this.column]);
          return this.direction === "asc" ? res : -res;
        })
        .slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    // without sort
    else
      return this.list.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
  }

  // #endregion
}

export type SortDirection = "asc" | "desc" | "";
const rotate: { [key: string]: SortDirection } = {
  asc: "desc",
  desc: "",
  "": "asc",
};

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: string;
  direction: SortDirection;
}
