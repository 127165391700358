export const MCBranches = {
  MC: 4,

  MC1Title: "MILLING_COMPANY_1",
  MC1BaseURL: "http://92.204.145.43:8081/millingDev/api/",

  MC2Title: "MILLING_COMPANY_2",
  MC2BaseURL: "http://192.168.1.38/milling/api/",

  MC3Title: "MILLING_COMPANY_3",
  MC3BaseURL: "http://192.168.1.41:8003/mc3/api/", // mc3 local server

  MC4Title: "شركة المطاحن الرابعة MC4",
  MC4BaseURL: "https://crmportal.mc4.com.sa/milling/api/", // production server
  // MC4BaseURL: "http://192.168.30.188/milling/api/", // test server
  // MC4BaseURL: "http://192.168.1.41:8004/mc4/api/", // local server
};

export const environment = {
  production: true,
  local_storage_private_key: "milling4EncryptionKey",

  // MC4 downloading excel sheet urls
  customerTemplateUrl:
    "https://crm.firstmills.com/milling/media/templates/CustomersTemplate.xlsx",
  BeneficiaryTemplateUrl:
    "https://crm.firstmills.com/milling/media/templates/BeneficiariesTemplate.xlsx",
  customerProductTemplateUrl:
    "https://crm.firstmills.com/milling/media/templates/ApprovedCustomerProductTemplate.xlsx",

  baseUrl:
    MCBranches.MC == 1
      ? MCBranches.MC1BaseURL
      : MCBranches.MC == 2
      ? MCBranches.MC2BaseURL
      : MCBranches.MC == 3
      ? MCBranches.MC3BaseURL
      : MCBranches.MC4BaseURL,
  title:
    MCBranches.MC == 1
      ? MCBranches.MC1Title
      : MCBranches.MC == 2
      ? MCBranches.MC2Title
      : MCBranches.MC == 3
      ? MCBranches.MC3Title
      : MCBranches.MC4Title,

  google_maps_key: "AIzaSyBqtphDqvKbaGEjk-BNVmXZE4YXe_11Vi4",
};
