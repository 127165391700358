export const COMPANY_APIS = {
  SAGO_UNAPPROVED: "home/reject-requests-by-sago",
  SAGO_APPROVED: "home/approved-requests-by-date",
  SAGO_PENDING: "home/sago-fifteen",
  SAGO_SENT: "home/sago-requests",
  SAGO_DELETED: "customer/sago/deleted-customers/list",
  REJECTED: "home/reject-requests-by-company",
  PENDING: "home/member-requests-by-date",
  SAGO_STATUS_UPDATE: "customer/sago/customer/status/",
  SAGO_STATUS_RESEND: "customer/sago/customer/resend",
  SAGO_STATUS_TRACE: "home/sago/reject-reson/",
  TRANSFER_STATUS_TRACE: "customer/get-status-of-customer-transfer/",
  COMMITTEE_CREATE: "user/register",
  COMMITTEE_ASSIGN: "customer/committee-form/create",
  COMMITTEE_LIST: "user/committee/list",
  NEW: "home/new-requests-by-date",

  JOIN_FORM_LIST: "customer/committee-form/flour/list",
  APPROVED_LIST: "customer/approved/flour/list",
  APPROVED_FEED_LIST: "customer/approved/feed/list",
  PRODUCT_HISTORY: "customer/approved/product/increase-history/",
  UPDATE_JOIN_REQUEST_PRODUCTS: "customer/update-requested-products/",

  SECOUND_VISITE_LIST: "customer/list-approved-customer-second-visit",

  REQUEST_LIST_BY_TYPE: "customer/list-committee-form/",
  DISTRIBUTER_CHANGE_LIST: "customer/list-transfer-to-another-distributor",
  BRANCH_CHANGE_LIST: "customer/list-transfer-to-branch-requests",
  COMPANY_TRANSFERS_LIST: "customer/list-transfer",

  TODO_LIST: "home/todo/list",

  SAGO_CLIENTS_EXPORT: "customer/approved/products/sago/export",
  SAGO_CLIENTS_PRODUCTS_EXPORT: "customer/approved/sago/export",
} as const;
