// Angular modules
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Location } from "@angular/common";

// Models
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";
import { customerFormDto } from "../../customer.utility";

import { ApiService } from "src/app/core/services/api.service";
import { TranslateService } from "@ngx-translate/core";

// 3rd-party tools
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-increase-amount-request-approval",
  templateUrl: "./increase-amount-request-approval.component.html",
  styleUrls: ["./increase-amount-request-approval.component.scss"],
})
export class IncreaseAmountRequestApprovalComponent implements OnInit {
  // #region varaibles
  request_status = new BehaviorSubject<number>(
    +this.activeRoute.snapshot.params["status"]
  );
  title: string;

  increaseRequestData$: Observable<customerFormDto>;

  // Table data
  tableDataHeader: { key: string; value: string }[] = TABLE_HEADERS;
  tableDataLength: number;

  // #endregion

  // #region Constructor

  constructor(
    private translation: TranslateService,
    private activeRoute: ActivatedRoute,
    private titleService: Title,
    public location: Location,
    private API: ApiService,
    public router: Router
  ) {
    this.title = "INCREASE_REQ";
    this.titleService.setTitle(`${this.translation.instant(this.title)}`);
  }

  // #endregion

  // #Region OnInit

  ngOnInit(): void {
    this.increaseRequestData$ = this.getCommitteeFormById(
      this.activeRoute.snapshot.params["form_id"]
    );
  }

  getCommitteeFormById(id: number) {
    return this.API.getDataFromServerById(
      CUSTOMER_APIS.JOIN_FORM_SINGLE,
      id
    ).pipe(
      map((res: any) => res.data),
      map((data) => {
        return {
          ...data,
          products: data.products.map((line) => {
            return {
              ...line,
              asked_amount: line.asked_packed_amount
                ? line.asked_packed_amount
                : line.asked_unpacked_amount,
              is_consumed_identical: line.is_consumed_identical ? "YES" : "NO",
              is_fair_amount: line.is_fair_amount ? "YES" : "NO",
            };
          }),
        };
      })
    );
  }

  // #endregion
}

export const TABLE_HEADERS = [
  { key: "product_name", value: "PRODUCT_NAME" },
  { key: "current_amount", value: "CURRENT_VALUE" },
  { key: "asked_amount", value: "QTY_REQUIRED" },
  { key: "is_consumed_identical", value: "QTY_IDENTICAL" },
  { key: "is_fair_amount", value: "QTY_SUITABLE" },
  { key: "received_amount", value: "QTY_RECIEVED" },
  { key: "committee_form_member_amount", value: "QTY_SUGGESTED" },
  { key: "reason", value: "REASON" },
];
