<app-custom-header header_title="{{ title }}"></app-custom-header>

<app-client-information
  (emitClientData)="getRequestData($event)"
  [approved]="false"
></app-client-information>

<div class="card mx-2 my-4 px-3 py-4">
  <h2 class="mx-0 mb-4">{{ "VISIT_DETAILS" | translate }} :</h2>
  <div class="row mb-4">
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "SHOP_PREP_NOTES" | translate }}</span> :
      {{ customerFormModel.shop_equipement_report ?? "NO_VALUE" | translate }}
    </div>
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "OWNER_NOTES" | translate }}</span> :
      {{ customerFormModel.shop_owner_notes ?? "NO_VALUE" | translate }}
    </div>
  </div>
  <hr />
  <h3>{{ "CLEAN_LEVEL" | translate }} :</h3>
  <div class="row mb-4">
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "TOOLS_CLEAN_LEVEL" | translate }}</span> :
      {{
        customerFormModel.shop_equipement_cleanliness ?? "NO_VALUE" | translate
      }}
    </div>
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "FLOOR_CLEAN_LEVEL" | translate }}</span> :
      {{ customerFormModel.floor_cleanliness ?? "NO_VALUE" | translate }}
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "STORE_CLEAN_LEVEL" | translate }}</span> :
      {{
        customerFormModel.customer_storage_warehouse_cleanliness ?? "NO_VALUE"
          | translate
      }}
    </div>
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "WORKERS_HEALTH_LICENCE" | translate }}</span> :
      {{
        customerFormModel.shop_workers_health_licenses ?? "NO_VALUE" | translate
      }}
    </div>
  </div>
</div>
<div class="card mx-2 my-4 px-3 py-4">
  <h2 class="mx-0 mb-4">{{ "ATTACHMENTS" | translate }} :</h2>
  <div class="row mb-4">
    <a
      *ngIf="customerFormModel?.committee_form_file"
      class="col-6 col-md-2 custom-right-border"
      target="_blank"
      [href]="customerFormModel?.committee_form_file"
      >{{ "SHOW_FIELD_VISIT" | translate }}
    </a>
    <a
      *ngIf="customerFormModel?.technical_visit_report_file"
      class="col-6 col-md-2 custom-right-border"
      target="_blank"
      [href]="customerFormModel?.technical_visit_report_file"
      >{{ "SHOW_TECHNICAL_VISIT" | translate }}
    </a>
    <a
      *ngIf="customerFormModel?.pledge"
      class="col-6 col-md-2 custom-right-border"
      target="_blank"
      [href]="customerFormModel?.pledge"
      >{{ "SHOW_PLEDGE" | translate }}
    </a>
  </div>
</div>
<div
  *ngIf="customerRequestModel.customer_category !== 2"
  class="card mx-2 my-4 px-3 py-4"
>
  <h2 class="mx-0 mb-4">{{ "PRODUCTS_LIST" | translate }} :</h2>
  <app-advancedtable
    [collectionSize]="productsLengthOfData"
    [headers]="productsTableHeader"
    [list]="productsTableData"
    [hasPagination]="false"
  >
  </app-advancedtable>
</div>
<div class="card mx-2 my-4 px-3 py-4">
  <h2 class="mx-0 mb-4">{{ "TOTAL_PROD_STATS" | translate }} :</h2>
  <div class="row mb-4">
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "TOTAL_PACKED" | translate }}</span> :
      {{
        customerFormModel.total_asked_packed_amount ?? "NO_VALUE" | translate
      }}
    </div>
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "TOTAL_ACTUAL_PACKED" | translate }}</span> :
      {{ customerFormModel.total_packed_amount ?? "NO_VALUE" | translate }}
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "TOTAL_UNPACKED" | translate }}</span> :
      {{
        customerFormModel.total_asked_unpacked_amount ?? "NO_VALUE" | translate
      }}
    </div>
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "TOTAL_ACTUAL_UNPACKED" | translate }}</span> :
      {{ customerFormModel.total_unpacked_amount ?? "NO_VALUE" | translate }}
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-md-6 custom-right-border">
      <span class="bold">{{ "COMMITTEE_VISIT_DATE" | translate }}</span> :
      {{ customerFormModel.actual_visit_date ?? "NO_VALUE" | translate }}
    </div>
  </div>
</div>
<div class="card mx-2 my-4 px-3 py-4">
  <h2 class="mx-0 mb-4">{{ "TRACE_FORM_STATUS" | translate }} :</h2>
  <app-advancedtable
    [collectionSize]="statusLengthOfData"
    [headers]="statusTableHeader"
    [list]="statusTableData"
    [hasPagination]="false"
  >
  </app-advancedtable>
</div>

<form-approval-actions
  [requestStatus]="request_status"
  [reAssign]="true"
></form-approval-actions>

s
