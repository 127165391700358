// angular modules
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { UserLoginResponseDto } from "src/app/auth/auth.utility";
import { AUTH_API } from "../apis/auth.api";

import { SessionTimeoutService } from "./session-timeout.service";
import { LocalStorageService } from "./local-storage.service";
import { ApiService } from "./api.service";
import { throwError } from "rxjs";

@Injectable()
export class SecurityService {
  readonly localKey = "mc-new-theme";
  private accessTokenInterval;

  get jwtToken() {
    return this.retrieveToken("access");
  }

  get user() {
    return this.retrieveUser();
  }

  private newAccessTokenJob() {
    this.accessTokenInterval = setInterval(() => {
      this.newAccessToken();
    }, 600000); // ~10 mins
  }

  private newAccessToken() {
    const refreshToken = this.retrieveToken("refresh");
    this.API.sendDataToServer<any>(AUTH_API.REFRESH_TOKEN, {
      refresh: refreshToken,
    }).subscribe(
      (response: any) => {
        this.localStorage.updateAccessToken(this.localKey, response);
      },
      () => {
        this.logout();
      }
    );
  }

  // #region constructor

  constructor(
    private readonly localStorageService: LocalStorageService,
    private sessionTimeout: SessionTimeoutService,
    private localStorage: LocalStorageService,
    private readonly router: Router,
    private API: ApiService
  ) {}

  // #endregion

  // #region actions

  login(response: any) {
    this.localStorage.store(this.localKey, response);
    this.router.navigate(["/pages/dashboard"]);
  }

  verify(response: any) {
    this.localStorage.store("temp_x", response);
    this.router.navigate(["verify-user"]);
  }

  logout() {
    this.stopTokenInterval();
    this.sessionTimeout.sessionSubscription.unsubscribe();
    this.blackListToken();
  }

  blackListToken() {
    const user_data = this.localStorageService.retrieve(this.localKey);
    if (user_data)
      this.API.sendDataToServer(AUTH_API.BLACKLIST_TOKEN, {}).subscribe({
        next: () => {
          this.localStorageService.remove(this.localKey);
          this.router.navigate(["/auth/login"]);
        },
        error: (err) => {
          this.router.navigate(["/auth/login"]);
          throwError(err);
        },
      });
  }

  getAccessToken() {
    this.stopTokenInterval();
    this.newAccessToken();
    this.newAccessTokenJob();
  }

  stopTokenInterval() {
    clearInterval(this.accessTokenInterval);
  }

  // #endregion

  // #region retrieve local storage actions

  retrieveToken(type: string) {
    const user = this.localStorageService.retrieve(this.localKey);
    return user && typeof user !== "undefined"
      ? (user as UserLoginResponseDto)[type]
      : null;
  }

  retrieveUser() {
    const user = this.localStorageService.retrieve(this.localKey);
    return user && typeof user !== "undefined"
      ? (user as UserLoginResponseDto)
      : null;
  }

  // #endregion
}
