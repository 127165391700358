<h2 class="text-center" mat-dialog-title>
  {{ "PRODUCT_HISTORY" | translate }}
</h2>
<mat-dialog-content>
  <app-advancedtable
    [headers]="tableHeader"
    [hasPagination]="false"
    [list]="tableData"
  >
  </app-advancedtable>
</mat-dialog-content>
