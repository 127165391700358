<app-custom-header header_title='{{title}}'></app-custom-header>

<div class="card mx-2 mb-4 px-4 pb-4">
    <app-table-filter
        (filterModel)="newFilterModel($event)"
        [hasData]="hasData$"
        [isRequest]="true"
        [isActive]="true"
        >
    </app-table-filter>
    <app-advancedtable
        (actionCreated)="onSelectedAction($event)"
        [collectionSize]="tableDataLength"
        (pageData)="tablePageChange($event)"
        [actions]="tableActions"
        [headers]="tableHeaders"
        [hasAction]="true"
        [list]="tableData"
    ></app-advancedtable>
</div> 