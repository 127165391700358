// Angular modules
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";

// Models
import { ApprovedUsersDto } from "src/app/pages/admin/admin.utility";
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";
import { CustomerDto } from "../../customer.utility";

// Services
import { SecurityService } from "src/app/core/services/security.service";
import { ApiService } from "src/app/core/services/api.service";
import { TranslateService } from "@ngx-translate/core";

// 3rd-Party tools
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-join-form-approval",
  templateUrl: "./join-form-approval.component.html",
  styleUrls: ["./join-form-approval.component.scss"],
})
export class JoinFormApprovalComponent implements OnInit {
  // #region varaibles
  request_status = new BehaviorSubject<number>(0);
  userType: string;
  title: string;

  // Models :
  customerRequestModel: CustomerDto;
  customerFormModel: CustomerDto;

  // Products table :
  productsLengthOfData: number;
  productsTableHeader: any[];
  productsTableData: any[];

  // Form-status-tracker table :
  statusLengthOfData: number;
  statusTableHeader: any[];
  statusTableData: any[];

  // #endregion

  // #Region Constructor

  constructor(
    public securityService: SecurityService,
    private translation: TranslateService,
    private activeRoute: ActivatedRoute,
    private titleService: Title,
    private datePipe: DatePipe,
    private API: ApiService,
    public router: Router
  ) {
    // #region init variables

    this.userType = this.securityService.retrieveUser().user_type;
    this.customerRequestModel = new CustomerDto();
    this.customerFormModel = new CustomerDto();

    this.customerFormModel.id = this.activeRoute.snapshot.params["id"];
    this.customerFormModel.form_number =
      this.activeRoute.snapshot.params["form_id"];

    this.title = "NEW_CLIENT_APPROVAL_FORM";
    this.titleService.setTitle(`${this.translation.instant(this.title)}`);
    this.productsTableData = [];
    this.statusTableData = [];

    this.productsTableHeader = [
      { key: "product_name", value: "PRODUCT_NAME" },
      { key: "asked_amount", value: "QTY_REQUIRED" },
      { key: "actual_amount", value: "QTY_APPROVED" },
    ];

    this.statusTableHeader = [
      { key: "name", value: "RESPONSIBLE_NAME" },
      { key: "user_type", value: "RESPONSIBLE_TYPE" },
      { key: "is_approved", value: "RESPONSIBLE_RESPONSE" },
      { key: "created", value: "DATE" },
      { key: "reason", value: "REASON" },
    ];

    // #endregion
  }

  // #endregion

  // #Region OnInit

  ngOnInit(): void {
    this.loadControls();
  }

  // #endregion

  // #region loadControls

  loadControls() {
    this.getCommitteeFormById(this.customerFormModel.form_number);
    this.getCommitteeApprovalTable(this.customerFormModel.id);
  }

  getCommitteeFormById(id: number) {
    this.API.getDataFromServerById(CUSTOMER_APIS.JOIN_FORM_SINGLE, id)
      .pipe(
        map((res: any) => res.data),
        map((data) => {
          return {
            ...data,
            products: data.products.map((line) => {
              return {
                ...line,
                asked_amount:
                  line.asked_packed_amount && line.asked_packed_amount !== 0
                    ? line.asked_packed_amount
                    : line.asked_unpacked_amount,
                actual_amount:
                  line.packed_amount && line.packed_amount !== 0
                    ? line.packed_amount
                    : line.unpacked_amount,
              };
            }),
          };
        })
      )
      .subscribe((res: CustomerDto) => {
        this.customerFormModel = res;
        this.productsTableData = res.products;
      });
  }

  getRequestData(data) {
    this.request_status.next(data.request_status);
  }

  translateUserType(line: ApprovedUsersDto): string {
    if (line.user_type === "enterprise") return "CEO_MANAGER";
    if (line.user_type === "branch") return "BRANCH_MANAGER";
    if (line.user_type === "sales") return "SALES_MANAGER";
  }

  detectReason(line: ApprovedUsersDto): string {
    if (!line.is_approved && line.is_request_for_info)
      return line.request_for_info_comment;
    if (!line.is_approved && !line.is_request_for_info)
      return line.reject_reason;
  }

  detectState(line: ApprovedUsersDto): string {
    return line.is_approved
      ? "ACCEPT"
      : line.is_request_for_info
      ? "PENDING"
      : "REJECT";
  }

  getCommitteeApprovalTable(id: number) {
    this.API.getDataFromServerById(CUSTOMER_APIS.FORM_APPROVAL_SINGLE, id)
      .pipe(
        map((res: any) => res.data),
        map((data) =>
          data.map((line: ApprovedUsersDto) => {
            return {
              ...line,
              created: this.datePipe.transform(line.created, "yyyy-MM-dd"),
              user_type: this.translateUserType(line),
              is_approved: this.detectState(line),
              reason: this.detectReason(line),
            };
          })
        )
      )
      .subscribe((res: any) => (this.statusTableData = res));
  }

  // #endregion
}
