<app-custom-header header_title="{{title}}"></app-custom-header>


<app-client-information  (emitClientData)="extractCustomerOldData($event)"  [approved]="false"></app-client-information>

<div *ngIf="hasProducts" class="card row mx-2">
  <h3 class="m-2">{{ "PRODUCTS" | translate }}</h3>
  <app-advancedtable
    [headers]="listOfHeaderTitle"
    [list]="listOfItems"
    [hasPagination]="false"
  ></app-advancedtable>
</div>
