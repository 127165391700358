import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";

import { customer_category } from "../../../core/utilities/shared_enums";
import { CustomerDto } from "src/app/pages/customer/customer.utility";
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";

import { SecurityService } from "src/app/core/services/security.service";
import { ApiService } from "src/app/core/services/api.service";
@Component({
  selector: "app-client-information",
  templateUrl: "./client-information.component.html",
  styleUrls: ["./client-information.component.scss"],
})
export class ClientInformationComponent implements OnInit {
  @Output() emitClientData = new EventEmitter<{ data: CustomerDto }>();
  @Input() approved: boolean;
  @Input() isProfile: boolean;

  clientData$: Observable<CustomerDto>;
  CATEGORY = customer_category;

  customer_id;
  request_id = this.route.snapshot.params["id"];

  listOfHeaderTitle = [
    { key: "product_name", value: "PRODUCT_NAME" },
    { key: "product", value: "PRODUCT_NUM" },
    { key: "amount", value: "CURRENT_QTY" },
  ];

  constructor(
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private API: ApiService
  ) {
    this.customer_id = this.route.snapshot.queryParams["customer"]
      ? this.route.snapshot.queryParams["customer"]
      : this.route.snapshot.params["customer"]
      ? this.route.snapshot.params["customer"]
      : this.securityService.retrieveUser().customer_id;
  }

  ngOnInit(): void {
    this.clientData$ = this.getClientData();
  }

  getClientData(): Observable<CustomerDto> {
    return this.approved == true
      ? this.API.getDataFromServerById(
          CUSTOMER_APIS.APPROVED_SINGLE,
          this.customer_id
        ).pipe(
          map((result) => result.data),
          tap((data) => this.emitClientData.emit(data))
        )
      : this.API.getDataFromServerById(
          CUSTOMER_APIS.JOIN_REQUEST_SINGLE,
          this.request_id
        ).pipe(
          map((result) => result.data),
          tap((data) => this.emitClientData.emit(data))
        );
  }
}
