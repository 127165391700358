<div id="layout-wrapper">

  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="main-content">
    <div class="page-content p-3">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer class="fixed-bottom w-100"></app-footer>
  </div>
</div>
