// Angular modules
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { ResponseDto } from "src/app/core/interfaces/api-response";
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";
import { COMPANY_APIS } from "src/app/core/apis/company.api";

import { ProductsHistoryDisplayComponent } from "src/app/shared/components-reusable/products-history-display/products-history-display.component";
import { Table_Actions as ACTIONS } from "src/app/shared/components-UI/advancedtable/advanced.model";

// Services
import { ApiService } from "src/app/core/services/api.service";
import { TranslateService } from "@ngx-translate/core";

import { MatDialog } from "@angular/material/dialog";
import { Direction } from "@angular/cdk/bidi";

@Component({
  selector: "app-client-view",
  templateUrl: "./client-view.component.html",
  styleUrls: ["./client-view.component.scss"],
})
export class ClientViewComponent implements OnInit {
  // #Region variables
  isDistributer: boolean;
  customerId: string;
  title: string;

  // name of lists
  listOfProducts: BeneficiaryProductsDto[];
  listOfBeneficiary: BeneficiaryDto[];
  listOfTotalProducts: totalProducts[] = [];
  listOftempProducts: any[];

  // products table data
  lengthOfData!: number;
  tableHeader: any[];
  totalsTableHeader: any[];
  tempTableHeader: any[];
  tableActions = [ACTIONS.product_history];

  dialogDirection: Direction;

  // #endregion

  // #Region Constructor

  constructor(
    private translation: TranslateService,
    private route: ActivatedRoute,
    private titleService: Title,
    public dialog: MatDialog,
    private API: ApiService
  ) {
    // #Region init variables
    this.isDistributer = false;

    this.title = "CLIENT_INFORMATION";
    this.titleService.setTitle(`${this.translation.instant(this.title)}`);
    this.customerId = this.route.snapshot.params["customer"];
    this.listOftempProducts = [];
    this.listOfBeneficiary = [];
    this.listOfProducts = [];

    this.tableHeader = [
      { key: "product", value: "PRODUCT_NUM" },
      { key: "product_name", value: "PRODUCT_NAME" },
      { key: "amount", value: "QTY" },
    ];

    this.totalsTableHeader = [
      { key: "product__code", value: "PROD_CODE" },
      { key: "product__title", value: "PRODUCT_NAME" },
      { key: "amount__sum", value: "QTY" },
    ];

    this.tempTableHeader = [
      { key: "product_code", value: "PROD_CODE" },
      { key: "product_name", value: "PRODUCT_NAME" },
      { key: "quantity_change", value: "QTY" },
      { key: "is_addition", value: "INCREASE/DECREASE" },
      { key: "from_date", value: "FROM_DATE" },
      { key: "to_date", value: "TO_DATE" },
      { key: "request_number", value: "REQ_NUM" },
      { key: "sago_record_id", value: "REQ_SAGO_NUM" },
    ];
  }

  // #endregion

  // #Region OnInit

  ngOnInit(): void {
    this.getClientProducts(+this.customerId);
  }

  // #endregion

  // #region loadControls

  getClientData({
    total_beneficiaries_product,
    approved_inc_temp_requests,
    approved_customer_products,
    approved_beneficiary_products,
    customer_category,
  }) {
    this.listOfTotalProducts = total_beneficiaries_product;

    if (customer_category == 4) {
      this.listOfProducts = approved_beneficiary_products;
    }
    if (customer_category == 1) {
      this.listOfProducts = approved_customer_products;
    }

    this.listOftempProducts = approved_inc_temp_requests.map((line: any) => {
      return {
        ...line,
        is_addition: line.is_addition ? "DECREASE" : "INCREASE",
      };
    });
  }

  getClientProducts(id: number) {
    this.API.getDataFromServerById(
      CUSTOMER_APIS.APPROVED_BENEFICIARY_SINGLE,
      id
    ).subscribe((res: ResponseDto) => {
      this.listOfProducts = res.data;
      this.isDistributer =
        this.listOfProducts.length > 0 &&
        this.listOfProducts[0].beneficiary > 0;
      if (this.isDistributer) this.handleDistributerBeneficiary();
    });
  }

  handleDistributerBeneficiary() {
    this.listOfProducts.forEach((prd) => {
      const isNew =
        this.listOfBeneficiary.findIndex(
          (benf) => prd.beneficiary === benf.beneficiary
        ) === -1;
      if (isNew) {
        const benf: BeneficiaryDto = {
          beneficiary: prd.beneficiary,
          beneficiary_name: prd.beneficiary_name,
          products: this.listOfProducts.filter(
            (p) => p.beneficiary === prd.beneficiary
          ),
        };
        this.listOfBeneficiary.push(benf);
      }
    });
  }

  openDisplayProductsHistory(api_result: any) {
    this.dialog.open(ProductsHistoryDisplayComponent, {
      data: { api_result },
      direction: "rtl",
      closeOnNavigation: true,
      width: "100%",
      height: "75vh",
    });
  }

  getProductHistory({ model }) {
    this.API.getDataFromServer(
      COMPANY_APIS.PRODUCT_HISTORY + this.customerId + `/${model.product}`
    ).subscribe((res) => {
      this.openDisplayProductsHistory(res.data);
    });
  }

  // #endregion
}

export class BeneficiaryProductsDto {
  approved_customer_name!: string;
  approved_customer!: number;
  beneficiary_name!: string;
  product_name!: string;
  beneficiary!: number;
  product!: number;
  amount!: number;
}

export class BeneficiaryDto {
  products: BeneficiaryProductsDto[];
  beneficiary_name!: string;
  beneficiary!: number;

  constructor() {
    this.products = [];
  }
}

export type totalProducts = {
  amount__sum: number;
  product__code: number;
  product__title: string;
};
