// #region imports
import { Component, Inject, OnInit } from "@angular/core";
// angular material
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ResponseDto } from "src/app/core/interfaces/api-response";
import { MatTableDataSource } from "@angular/material/table";
// services
import { NotificationMessageService } from "src/app/core/services/notification.message.service";
import { ApiService } from "src/app/core/services/api.service";
import { COMPANY_APIS } from "src/app/core/apis/company.api";
import { DialogService } from "src/app/core/services/dialog.service";
// #endregion
@Component({
  selector: "app-assign-join-request",
  templateUrl: "./assign-join-request.component.html",
  styleUrls: ["./assign-join-request.component.scss"],
})
export class AssignJoinRequestComponent implements OnInit {
  // #region declare variables
  model: AssignCommitteUserDto;
  listOfHeaderTitle: any[];
  list: CommitteUserDto[] = [];
  dataSource = new MatTableDataSource(this.list);
  // #endregion

  // #region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogService: DialogService,
    public dialog: MatDialog,
    private API: ApiService
  ) {
    // init variables
    this.model = new AssignCommitteUserDto();
    this.list = [];
    this.listOfHeaderTitle = [
      { key: "username", value: "USER_NAME" },
      { key: "full_name", value: "COMMITTEE" },
      { key: "mobile", value: "MOBILE_NUM" },
    ];
    this.model.customer = this.data.id;
    this.model.assigned_to = this.data.assigned_to_id;
  }
  // #endregion

  // #region ngOnInit

  ngOnInit(): void {
    this.loadControls();
  }

  // #endregion

  // #region load controls

  loadControls() {
    this.getListOfCommitedUsers();
  }

  getListOfCommitedUsers() {
    this.API.getDataFromServer(COMPANY_APIS.COMMITTEE_LIST).subscribe(
      (response: ResponseDto) => {
        this.list = response.data.map((item) => {
          return { ...item, full_name: item.first_name + " " + item.last_name };
        });
      }
    );
  }

  // #endregion

  // #region main actions

  sendCommittedUser() {
    this.API.sendDataToServer(
      COMPANY_APIS.COMMITTEE_ASSIGN,
      this.model
    ).subscribe(() => {
      this.dialog.closeAll();
      this.dialogService.savedSuccessfully();
    });
  }

  onLineChose(id: number) {
    this.model.assigned_to = id;
  }
  // #endregion
}
export class AssignCommitteUserDto {
  assigned_to_id: number;
  assigned_to: number;
  customer: number;
}
export class CommitteUserDto {
  first_name: string;
  last_name: string;
  username: string;
  mobile: string | number;
}
