// Table data
export interface Table {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: string;
}

// Search Data
export interface SearchResult {
  tables: Table[];
  total: number;
}

// Table data
export interface Table {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: string;
}
export interface SearchResult {
  tables: Table[];
  total: number;
}

export const Table_Actions = {
  update_request: "UPDATE_REQ",
  review_request: "REVIEW_REQ",
  update_form: "UPDATE_FORM",
  review_form: "REVIEW_FORM",
  assign_committee_member: "COMMITTEE_ASSIGN",
  resend_request: "RESEND_REQ",
  update_status: "STATUS_UPDATE",
  follow_up_status: "STATUS_FOLLOW",
  update_user_data: "UPDATE_USER_DATA",
  change_password: "CHANGE_PASSWORD",
  delete: "DELETE",
  view_beneficiary_data: "VIEW_BENIFI_DATA",
  update_beneficiary_data: "UPDATE_BENIFI_DATA",
  update_beneficiary_products: "UPDATE_BENIFI_PROD",
  review_client: "CLIENT_DATA",
  attachment: "ATTACH",
  change_sap_number: "EDIT_SAP_NUM",
  edit: "EDIT",
  correct_client_data: "CORRECT_CLIENT_DATA",

  product_history: "PRODUCT_HISTORY",

  increase_products_request: "INCREASE_REQ",
  temp_products_request: "TEMP_INCREASE_REQS",
  change_info_request: "CHANGE_INFO_REQ",
  change_products_request: "PRODUCTS_EDIT_REQ",
  change_branch_request: "BRANCH_CHANGE_REQ",
  change_distributer_request: "DISTRIBUTER_T_DISTRIBUTER",
  change_category_request: "CATEGORY_CHANGE_REQ",
};

export class tablePaginationDto {
  previousPageIndex: number;
  pageIndex: number;
  pageSize: number;
  length: number;
}
