<div class="container-fluid">
  <app-custom-header header_title="{{ title }}"></app-custom-header>

  <app-client-information
    (emitClientData)="getClientData($event)"
    [approved]="true"
  ></app-client-information>

  <!-- Products section -->
  <div
    *ngIf="isDistributer === false && listOfProducts.length"
    class="card mx-2 py-4 mb-4"
  >
    <h3 class="px-3">{{ "PRODUCTS_LIST" | translate }} :</h3>
    <app-advancedtable
      class="px-3"
      [headers]="tableHeader"
      [list]="listOfProducts"
      [actions]="tableActions"
      [hasAction]="true"
      (actionCreated)="getProductHistory($event)"
      [hasPagination]="false"
    >
    </app-advancedtable>

    <hr />
    <h3 class="px-3">{{ "TEMP_PRODUCTS_LIST" | translate }} :</h3>
    <app-advancedtable
      class="px-3"
      [list]="listOftempProducts"
      [headers]="tempTableHeader"
      [hasPagination]="false"
    >
    </app-advancedtable>
  </div>

  <div
    *ngIf="isDistributer === true && listOfProducts"
    class="card mx-2 py-4 mb-5"
  >
    <h3 class="px-3">{{ "TOTALS" | translate }} :</h3>
    <app-advancedtable
      class="px-3"
      [headers]="totalsTableHeader"
      [list]="listOfTotalProducts"
    >
    </app-advancedtable>

    <mat-divider class="my-3"></mat-divider>

    <h3 class="px-3">{{ "BENEFICIARIES" | translate }} :</h3>
    <mat-accordion class="card px-3">
      <mat-expansion-panel *ngFor="let benf of listOfBeneficiary">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ benf.beneficiary_name }}
          </mat-panel-title>
          <mat-panel-description>
            {{ "BENEFICIARY_NUM" | translate }} : {{ benf.beneficiary }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <h4>{{ "BENEFICIARY_PRODUCTS" | translate }} :</h4>
        <app-advancedtable [headers]="tableHeader" [list]="benf.products">
        </app-advancedtable>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
