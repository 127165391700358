// Core Modules
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

// Utilities
import { request_status_code as STATUS } from "src/app/core/utilities/shared_enums";
import { formApprovalDto } from "src/app/pages/company/company.utility";
import { user_type as USER } from "src/app/core/utilities/shared_enums";
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";

// Services
import { SecurityService } from "src/app/core/services/security.service";
import { DialogService } from "src/app/core/services/dialog.service";
import { ApiService } from "src/app/core/services/api.service";

import { Observable } from "rxjs";

@Component({
  selector: "form-approval-actions",
  templateUrl: "./form-approval-actions.component.html",
  styleUrls: ["./form-approval-actions.component.scss"],
})
export class FormApprovalActionsComponent implements OnInit {
  // Inputs
  @Input() requestStatus: Observable<number>; // Required input
  @Input() formStatus: Observable<string>;
  @Input() withForm: boolean = false;
  @Input() previousRoute: string;
  @Input() reAssign: boolean;

  @Input() hasEdit: { active: boolean; request_type: string };

  // Outputs
  @Output() actionEmitted = new EventEmitter<string>();

  // Variables
  userType: string = this.security.retrieveUser()?.user_type;
  approvalModel: formApprovalDto = new formApprovalDto();
  isVisible: boolean = false;

  // Optional properties
  outerFormStatus: string;

  constructor(
    private dialogService: DialogService,
    private activeRoute: ActivatedRoute,
    private security: SecurityService,
    private dialog: DialogService,
    private location: Location,
    private API: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.requestStatus.subscribe((status) => this.checkActionsVisible(status));
    if (this.withForm)
      this.formStatus.subscribe((status) => (this.outerFormStatus = status));
  }

  checkActionsVisible(status: number) {
    if (
      (this.userType === USER.ENTERPRISE &&
        status === STATUS.is_ToEnterprise) ||
      (this.userType === USER.BRANCH && status === STATUS.is_ToBranch) ||
      (this.userType === USER.SALES && status === STATUS.is_ToSales)
    )
      this.isVisible = true;
  }

  // Main logic region:
  committeeFormStatusUpdate(model: formApprovalDto) {
    this.API.sendDataToServer(
      CUSTOMER_APIS.FORM_APPROVAL_ACTION,
      model
    ).subscribe(() => {
      model = new formApprovalDto();
      this.dialogService.savedSuccessfully();
      this.location.back();
    });
  }

  approveCommitteeForm() {
    if (this.withForm && this.outerFormStatus !== "VALID") {
      this.dialog.alertMessage("CHECK_DATA_ANOTHER_TIME");
    } else {
      this.dialogService
        .confirmSavingData("REQUEST_SAVED_MOVED_NEXT")
        .then((res) => {
          if (res.isDismissed) this.approvalModel = new formApprovalDto();
          if (res.isConfirmed) {
            this.approvalModel.committee_form =
              this.activeRoute.snapshot.params["form_id"];
            this.actionEmitted.emit(FormAction.ACCEPT);
            this.approvalModel.is_approved = true;
            this.committeeFormStatusUpdate(this.approvalModel);
          }
        });
    }
  }

  rejectCommitteeForm() {
    this.actionEmitted.emit(FormAction.REJECT);
    this.approvalModel.is_approved = false;
    this.dialogService
      .confirmActionWithMessage(
        "سبب رفض استمارة الإنضمام",
        "السبب",
        this.approvalModel
      )
      .then((res) => {
        if (res.isDismissed) this.approvalModel = new formApprovalDto();
        if (res.isConfirmed) {
          this.approvalModel.committee_form =
            this.activeRoute.snapshot.params["form_id"];
          this.approvalModel.reject_reason = res.value;
          this.committeeFormStatusUpdate(this.approvalModel);
        }
      });
  }

  reAssignCommitteeForm() {
    this.actionEmitted.emit(FormAction.REASSIGN);
    this.approvalModel.is_request_for_info = true;
    this.dialogService
      .confirmActionWithMessage(
        "سبب إعادة تعيين الإستمارة",
        "التعليق",
        this.approvalModel
      )
      .then((res) => {
        if (res.isDismissed) this.approvalModel = new formApprovalDto();
        if (res.isConfirmed) {
          this.approvalModel.committee_form =
            this.activeRoute.snapshot.params["form_id"];
          this.approvalModel.request_for_info_comment = res.value;
          this.committeeFormStatusUpdate(this.approvalModel);
        }
      });
  }

  editCurrentRequest() {
    this.router.navigate([this.editScreensURLs[this.hasEdit.request_type]], {
      queryParams: {
        customer: this.activeRoute.snapshot.params["id"],
      },
    });
  }

  private editScreensURLs = {
    change_info: "/pages/customer/edit_profile/update",
  };
}

export enum FormAction {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  REASSIGN = "REASSIGN",
  UPDATE = "UPDATE",
}
