<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex align-items-center">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/pages/dashboard" class="logo logo-light">
          <span class="logo">
            <img [src]="mc_logo" />
          </span>
        </a>
      </div>

      <button mat-icon-button (click)="toggleMobileMenu($event)">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <div class="d-flex">
      <!-- Language -->
      <app-languages-menu class="mt-2"></app-languages-menu>

      <!-- User actions -->
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="../../../assets/app-assets/logo/arabic-avatar.jpg"
            alt="Header Avatar"
          />
          <span
            *ngIf="securityService.user?.name"
            class="d-xl-inline-block mx-2"
            >{{ securityService.user?.name }}
          </span>
          <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          <p>
            {{ USER[userType] }}
            <span *ngIf="userType == 'flour'"
              >( {{ customer_category_name }} )</span
            >
          </p>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            *ngIf="userType == 'flour' || userType == 'feed'"
            class="dropdown-item"
            routerLink="/pages/customer/profile"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ "PROFILE" | translate }}</a
          >
          <a class="dropdown-item" routerLink="/auth/reset-password"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ "CHANGE_PASSWORD" | translate }}</a
          >
          <a
            *ngIf="userType == 'admin'"
            class="dropdown-item d-block"
            routerLink="/pages/admin/settings"
            ><i class="bx bx-wrench font-size-16 align-middle me-1"></i
            >{{ "SETTINGS" | translate }}
          </a>

          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "LOGOUT" | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
