export class FilterModelDto {
  customer_category!: number;
  request_number!: string;
  request_type!: string;
  request_status!: number;
  branch!: number;
  mobile!: number;
  name!: string;
  is_deleted: boolean = false;
  internal_customer_type!: string;
  first_name!: string;
  username!: string;

  customer_number!: number;
  sago_customer_id!: number;
  sap_customer_id!: number;
  order_by!: string;

  constructor() {
    this.order_by = "-id";
  }
}
export class formApprovalDto {
  request_for_info_comment: string;
  is_request_for_info: boolean;
  committee_form: number;
  reject_reason: string;
  is_approved: boolean;
}
