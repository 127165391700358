import { Injectable } from "@angular/core";
import * as Crypto from "crypto-js";
import { environment } from "src/environments/environment";

@Injectable()
export class LocalStorageService {
  private localStorage: any;

  private encrypt(val: string): string {
    return Crypto.AES.encrypt(
      val,
      environment.local_storage_private_key
    ).toString();
  }

  private decrypt(val: string) {
    return Crypto.AES.decrypt(
      val,
      environment.local_storage_private_key
    ).toString(Crypto.enc.Utf8);
  }

  constructor() {
    this.localStorage = window.localStorage;
  }

  retrieve(key: string) {
    let value;
    value = this.localStorage.getItem(key);
    return value && JSON.parse(this.decrypt(value));
  }

  store<T>(key: string, value: T) {
    let stringifiedValue = JSON.stringify(value);
    this.localStorage.setItem(key, this.encrypt(stringifiedValue));
  }

  updateAccessToken(key: string, value: { access: string; refresh: string }) {
    const data = this.retrieve(key);
    const updated = { ...data, refresh: value.refresh, access: value.access };
    this.store(key, updated);
  }

  remove(key: string) {
    this.localStorage.removeItem(key);
  }

  removeAll() {
    this.localStorage.clear();
  }
}
