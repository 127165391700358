// Core modules
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MatNativeDateModule } from "@angular/material/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

// Modules
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";

// 3rd-party modules
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

// Services
import { createTranslateLoader } from "./core/services/language.service";

// Components
import { AppComponent } from "./app.component";
import "./core/plugins/date.extension";
import { SessionStorageService } from "./core/services/session-storage.service";
import { LocalStorageService } from "./core/services/local-storage.service";
import { SecurityService } from "./core/services/security.service";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    LayoutsModule,
    MatNativeDateModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "ar",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [SessionStorageService, LocalStorageService, SecurityService],
})
export class AppModule {}
