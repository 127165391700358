// Angular modules
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";

// Models
import { request_status_code } from "src/app/core/utilities/shared_enums";
import { ResponseDto } from "src/app/core/interfaces/api-response";
import { CUSTOMER_APIS } from "src/app/core/apis/customer.api";
import { customerFormDto } from "../../customer.utility";
import { CustomerDto } from "../../customer.utility";

// Services
import { SecurityService } from "src/app/core/services/security.service";
import { DialogService } from "src/app/core/services/dialog.service";
import { ApiService } from "src/app/core/services/api.service";
import { TranslateService } from "@ngx-translate/core";
import { MCBranches } from "src/environments/environment";

@Component({
  selector: "app-increase-amount-request-details",
  templateUrl: "./increase-amount-request-details.component.html",
  styleUrls: ["./increase-amount-request-details.component.scss"],
})
export class IncreaseAmountRequestDetailsComponent implements OnInit {
  // #region varaibles
  allowEdit: boolean;
  userType: string;
  title: string;
  MC: number = MCBranches.MC;

  // request_status: number;
  isSubmitted: boolean;

  // Models
  committeeVisitModel: customerFormDto;
  customerRequestModel: CustomerDto;

  // Forms
  committeeVisitProductsForm: FormGroup;
  committeeVisitForm: FormGroup;
  committeeFormData: FormData;

  // #region Constructor

  constructor(
    private securityService: SecurityService,
    private translation: TranslateService,
    private dialogService: DialogService,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private datePipe: DatePipe,
    private API: ApiService,
    public router: Router
  ) {
    // #region init variables
    this.showRequestForm(this.activeRoute.snapshot.params.status);
    this.userType = this.securityService.retrieveUser().user_type;
    this.title = "INCREASE_REQ";
    this.titleService.setTitle(`${this.translation.instant(this.title)}`);

    this.committeeVisitModel = new customerFormDto();
    this.customerRequestModel = new CustomerDto();
    this.committeeFormData = new FormData();
    this.committeeVisitModel.form_id = this.activeRoute.snapshot.params.form_id;
    this.committeeVisitModel.req_id = this.activeRoute.snapshot.params.id;

    // #endregion

    // init functions
    this.initForms();

    // #endregion
  }

  showRequestForm(status: number) {
    status == request_status_code.is_rejectedByCompany ||
    status == request_status_code.is_missingDocuments ||
    status == request_status_code.is_underOperation ||
    status == request_status_code.is_reassigned
      ? (this.allowEdit = true)
      : (this.allowEdit = false);
  }

  // #endregion

  // #Region OnInit

  ngOnInit(): void {
    this.allowEdit ? this.loadControls() : null;
  }

  // #endregion

  // #region loadControls

  loadControls() {
    this.getCommitteeFormById(this.committeeVisitModel.form_id);
    this.getRequestById(this.committeeVisitModel.req_id);
  }

  getRequestById(id: number) {
    this.API.getDataFromServerById(
      CUSTOMER_APIS.JOIN_REQUEST_SINGLE,
      id
    ).subscribe((res: ResponseDto) => {
      this.customerRequestModel = res.data;
    });
  }

  getCommitteeFormById(id: number) {
    this.API.getDataFromServerById(
      CUSTOMER_APIS.JOIN_FORM_SINGLE,
      id
    ).subscribe((res: ResponseDto) => {
      res.data.products.forEach(() =>
        this.productsArray.push(this.generateLineForm())
      );
      this.productsArray.patchValue(res.data.products);
    });
  }

  // #endregion

  // #region Form

  initForms() {
    this.committeeVisitForm = this.formBuilder.group({
      actual_visit_date: [null, [Validators.required, this.dateValidator]],
      shop_equipement_report: [null, [Validators.required]],
      shop_owner_notes: [null, [Validators.required]],

      products: this.formBuilder.array([], [Validators.required]),

      committee_form_file: [null, [Validators.required]],
      technical_visit_report_file: [null, [Validators.required]],
    });
  }

  get myForm() {
    return this.committeeVisitForm.controls;
  }

  get productsArray(): FormArray {
    return this.committeeVisitForm.get("products") as FormArray;
  }

  generateLineForm(): FormGroup {
    return (this.committeeVisitProductsForm = this.formBuilder.group({
      committee_form_member_amount: [
        null,
        [Validators.required, this.negativeValueValidator],
      ],
      received_amount: [
        null,
        [Validators.required, this.negativeValueValidator],
      ],
      reason: [null, [Validators.required]],
      committee_form: [this.activeRoute.snapshot.params.form_id],
      asked_unpacked_amount: [null],
      asked_packed_amount: [null],
      is_consumed_identical: [null],
      is_fair_amount: [null],
      is_addition: [null],
      current_amount: [null],
      product_name: [null],
      product: [null],
      weight_type: [null],
      id: [null],
      packed_amount: [null],
      unpacked_amount: [null],
    }));
  }

  // #region Form-validations

  negativeValueValidator(control: FormControl): Object {
    if (control.value < 0) return { isNegative: true };
    else return null;
  }

  dateValidator(control: FormControl): Object {
    if (control && control.value && new Date(control.value) > new Date())
      return { isNotInRange: true };
    else return null;
  }

  // #endregion

  // #region Actions

  settingFinalAmount(value, index) {
    let weight_type =
      this.productsArray.controls[index].get("weight_type").value;
    if (weight_type === "1")
      this.productsArray.controls[index].get("packed_amount").setValue(value);
    if (weight_type === "3")
      this.productsArray.controls[index].get("unpacked_amount").setValue(value);
  }

  formatSentData(form: FormGroup) {
    for (let key in form.value) {
      if (
        key !== "technical_visit_report_file" &&
        key !== "committee_form_file" &&
        key !== "actual_visit_date" &&
        key !== "products"
      )
        this.committeeFormData.append(key, form.value[key]);
      if (key == "actual_visit_date")
        this.committeeFormData.append(
          key,
          this.datePipe.transform(form.value[key], "yyyy-MM-dd")
        );
    }
    this.committeeFormData.append(
      "assigned_to",
      this.activeRoute.snapshot.params.assign_to
    );
    this.committeeFormData.append(
      "customer",
      this.activeRoute.snapshot.params.id
    );
  }

  uploadFile(input: any, fileName: string) {
    if (
      input.target.files[0].type == "application/pdf" ||
      (this.MC == 4 && input.target.files[0].type.split("/")[0] == "image") ||
      input.target.files[0].type == "application/pdf"
    ) {
      let file = input.target.files[0];
      this.committeeFormData.append(
        fileName,
        file,
        `${new Date().getTime()}_${file.lastModified}_attachment.${
          file.type.split("/")[1]
        }`
      );
    } else {
      input.target.value = null;
      this.MC == 4
        ? this.dialogService.alertMessage("FILE_FORMAT_PDF_IMG")
        : this.dialogService.alertMessage("FILE_FORMAT_PDF");
    }
  }

  // #endregion

  // #region submission;

  saveCommitteeSuggestedProducts(products: any) {
    this.API.updateDataOnServer(
      "put",
      CUSTOMER_APIS.JOIN_FORM_PRODUCTS_UPDATE +
        this.activeRoute.snapshot.params.form_id,
      products
    ).subscribe(() => {
      this.dialogService.successAndRouteTo(
        "/pages/customer/increase-amount/list/flour"
      );
    });
  }

  saveCommitteeForm(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      this.isSubmitted = false;
      this.formatSentData(form);
      this.API.updateDataOnServer(
        "put",
        CUSTOMER_APIS.JOIN_FORM_UPDATE +
          this.activeRoute.snapshot.params["form_id"],
        this.committeeFormData
      ).subscribe(() =>
        this.saveCommitteeSuggestedProducts({ products: form.value.products })
      );
    } else {
      this.dialogService.alertMessage("CHECK_DATA_ANOTHER_TIME");
    }
  }

  // #endregion
}
