<app-custom-header header_title="{{ title }}"></app-custom-header>

<ng-container *ngIf="allowEdit; else warning">
  <app-client-information [approved]="true"></app-client-information>

  <div class="card mx-2 py-4">
    <form
      id="committeeForm"
      [formGroup]="committeeVisitForm"
      (ngSubmit)="saveCommitteeForm(committeeVisitForm)"
    >
      <h2 class="mx-0 mb-4 px-5">{{ "COMMITTEE_REPORT" | translate }} :</h2>
      <mat-tab-group class="mx-5" dynamicHeight>
        <mat-tab label="{{ 'VISIT_PREPARATION' | translate }}">
          <div class="p-4">
            <mat-form-field class="col-sm-12 col-md-6 row" appearance="outline">
              <mat-label>{{ "COMMITTEE_VISIT_DATE" | translate }}</mat-label>
              <div class="mat-date-picker">
                <input
                  [(ngModel)]="committeeVisitModel.actual_visit_date"
                  formControlName="actual_visit_date"
                  [matDatepicker]="visit"
                  matInput
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="visit"
                ></mat-datepicker-toggle>
                <mat-datepicker touchUi #visit></mat-datepicker>
              </div>
              <mat-error *ngIf="myForm.actual_visit_date.errors?.required">
                {{ "VISIT_DATE_REQUIRED" | translate }}
              </mat-error>
              <mat-error *ngIf="myForm.actual_visit_date.errors?.isNotInRange">
                {{ "DATE_REQUIRED_NOT_FUTURE" | translate }}
              </mat-error>
            </mat-form-field>
            <div class="row my-3">
              <mat-form-field appearance="outline" class="col-sm-12 col-md-6">
                <mat-label>{{ "SHOP_PREP_NOTES" | translate }}</mat-label>
                <textarea
                  [(ngModel)]="committeeVisitModel.shop_equipement_report"
                  formControlName="shop_equipement_report"
                  matInput
                >
                </textarea>
                <mat-error
                  *ngIf="myForm.shop_equipement_report.errors?.required"
                >
                  {{ "SHOP_PREPARATION_REPORT" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-sm-12 col-md-6">
                <mat-label>{{ "OWNER_NOTES" | translate }}</mat-label>
                <textarea
                  [(ngModel)]="committeeVisitModel.shop_owner_notes"
                  formControlName="shop_owner_notes"
                  matInput
                >
                </textarea>
                <mat-error *ngIf="myForm.shop_owner_notes.errors?.required">
                  {{ "COMMITTEE_NOTES_REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'REQUIRED_PRODUCTS' | translate }}">
          <div class="p-4">
            <ng-container class="table-responsive" formArrayName="products">
              <table class="table table-hover text-center">
                <thead class="table-light align-middle text-wrap">
                  <tr>
                    <th>{{ "PROD_CODE" | translate }}</th>
                    <th>{{ "PRODUCT_NAME" | translate }}</th>
                    <th>{{ "QTY_REQUIRED" | translate }}</th>
                    <th>{{ "CURRENT_VALUE" | translate }}</th>
                    <th>{{ "QTY_IDENTICAL" | translate }}</th>
                    <th>{{ "QTY_SUITABLE" | translate }}</th>
                    <th>{{ "QTY_RECIEVED" | translate }}</th>
                    <th>{{ "QTY_SUGGESTED" | translate }}</th>
                    <th>{{ "REASON" | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="
                      let productLine of productsArray.controls;
                      let count = index
                    "
                  >
                    <tr [formGroupName]="count" class="table_row">
                      <td class="align-middle">
                        {{ productLine.value.product }}
                      </td>
                      <td class="align-middle">
                        {{ productLine.value.product_name }}
                      </td>
                      <td class="align-middle">
                        {{
                          productLine.value.asked_packed_amount
                            ? productLine.value.asked_packed_amount
                            : productLine.value.asked_unpacked_amount
                        }}
                      </td>
                      <td class="align-middle">
                        {{ productLine.value.current_amount }}
                      </td>
                      <td class="align-middle">
                        <mat-checkbox
                          formControlName="is_consumed_identical"
                          color="primary"
                        >
                        </mat-checkbox>
                      </td>
                      <td class="align-middle">
                        <mat-checkbox
                          formControlName="is_fair_amount"
                          color="primary"
                        >
                        </mat-checkbox>
                      </td>
                      <td class="align-middle">
                        <mat-form-field
                          appearance="outline"
                          style="width: 5rem !important"
                        >
                          <input
                            min="0"
                            formControlName="received_amount"
                            type="number"
                            matInput
                          />
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            isSubmitted &&
                            productLine.controls.received_amount.errors
                              ?.required
                          "
                        >
                          {{ "QTY_RECIEVED_REQUIRED" | translate }}
                        </mat-error>
                      </td>
                      <td class="align-middle">
                        <mat-form-field
                          appearance="outline"
                          style="width: 5rem !important"
                        >
                          <input
                            (ngModelChange)="settingFinalAmount($event, count)"
                            min="0"
                            formControlName="committee_form_member_amount"
                            type="number"
                            matInput
                          />
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            isSubmitted &&
                            productLine.controls.committee_form_member_amount
                              .errors?.required
                          "
                        >
                          {{ "QTY_SUGGESTED_REQUIRED" | translate }}
                        </mat-error>
                      </td>
                      <td>
                        <mat-form-field
                          appearance="outline"
                          style="width: 15rem !important"
                        >
                          <input
                            formControlName="reason"
                            type="text"
                            matInput
                          />
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            isSubmitted &&
                            productLine.controls.reason.errors?.required
                          "
                        >
                          {{ "REASON_REQUIRED" | translate }}
                        </mat-error>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'ATTACHMENTS' | translate }}">
          <div class="p-4 row">
            <div class="col-md-6 d-flex flex-column align-items-start">
              <label for="formFile" class="row mb-2">
                <div class="d-flex">
                  <mat-icon> attach_file</mat-icon>
                  <span>
                    {{ "FLIELD_VISIT_COPY" | translate }}
                  </span>
                </div>
                <small class="text-primary">
                  {{ "FILE_EXTENTION" | translate }}
                </small>
              </label>
              <input
                (change)="uploadFile($event, 'committee_form_file')"
                accept="image/*,.pdf"
                formControlName="committee_form_file"
                class="form-control w-50"
                type="file"
              />
              <mat-error
                *ngIf="
                  isSubmitted && myForm.committee_form_file.errors?.required
                "
              >
                {{ "ATTACH_REQUIRED" | translate }}
              </mat-error>
              <div class="my-3" *ngIf="committeeVisitModel.committee_form_file">
                <a
                  target="_blank"
                  [href]="committeeVisitModel.committee_form_file"
                >
                  {{ "SHOW_FILE" | translate }}
                </a>
              </div>
            </div>
            <div class="col-md-6 d-flex flex-column align-items-start">
              <label for="formFile" class="row mb-2">
                <div class="d-flex">
                  <mat-icon> attach_file</mat-icon>
                  <span>
                    {{ "TECHNICAL_VISIT_COPY" | translate }}
                  </span>
                </div>
                <small class="text-primary">
                  {{ "FILE_EXTENTION" | translate }}
                </small>
              </label>
              <input
                (change)="uploadFile($event, 'technical_visit_report_file')"
                accept="image/*,.pdf"
                formControlName="technical_visit_report_file"
                class="form-control w-50"
                type="file"
              />
              <mat-error
                *ngIf="
                  isSubmitted &&
                  myForm.technical_visit_report_file.errors?.required
                "
              >
                {{ "ATTACH_REQUIRED" | translate }}
              </mat-error>
              <div
                class="my-3"
                *ngIf="committeeVisitModel.technical_visit_report_file"
              >
                <a
                  target="_blank"
                  [href]="committeeVisitModel.technical_visit_report_file"
                >
                  {{ "SHOW_FILE" | translate }}
                </a>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>

  <div class="row mx-2 mb-5 pb-5 w-50 gap-3 justify-content-start">
    <button
      form="committeeForm"
      type="submit"
      class="btn btn-success col-sm-6 col-md-4"
    >
      {{ "SAVE_REQ" | translate }}
    </button>
    <button
      routerLink="/pages/customer/increase-amount/list/flour"
      type="button"
      class="btn btn-secondary col-sm-6 col-md-4"
    >
      {{ "BACK" | translate }}
    </button>
  </div>
</ng-container>

<ng-template #warning>
  <div class="mx-2 w-100 pb-5 alert alert-warning d-flex" role="alert">
    <div class="p-3 align-items-center w-100 text-center">
      <mat-icon class="text-danger fs-1">error_outline</mat-icon>
      <div class="fs-3 mt-2">
        {{ "REQUEST_UNDER_SUPERVISION" | translate }} ...
      </div>
    </div>
  </div>
</ng-template>
