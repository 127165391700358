import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { SessionStorageService } from './../../core/services/session-storage.service';
import { SecurityService } from './../../core/services/security.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    hasPermission = false;

    constructor(
        private sessionStorageService: SessionStorageService,
        private readonly securityService: SecurityService,
        public router: Router
        ) {}

    // activate layout if user have valid token
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.sessionStorageService.store('last-tab-url', state.url);
        if (!this.securityService.jwtToken) {
            this.securityService.logout();
            return false;
        } else {
            return true;
        }
    }
}
