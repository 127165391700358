<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" *ngIf="item.isTitle">
          {{ item.label | translate }}
        </li>
        <li *ngIf="!item.isTitle && !item.isLayout">
          <!-- Main item without subitems -->
          <a [routerLink]="item.link" *ngIf="!hasItems(item)" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
          </a>

          <!-- Main item With subitems -->
          <a *ngIf="hasItems(item)" href="javascript:void(0);">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span > {{ item.label | translate }}</span>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems" class="px-3">

              <!-- Subitem without subitems -->
              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)"
                [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                  <i class="bx {{ subitem.icon }}" *ngIf="subitem.icon"></i>
                  <span>{{ subitem.label | translate }}</span>
              </a>

              <!-- Subitem with subitems -->
              <a *ngIf="hasItems(subitem)" href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId">
                <i class="bx {{ subitem.icon }}" *ngIf="subitem.icon"></i>
                <span class="fw-bold">{{ subitem.label | translate }}</span>
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems" class="px-3">
                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->