// Core modules
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MCBranches } from "src/environments/environment";

// Services
import { SecurityService } from "src/app/core/services/security.service";
import { TranslateService } from "@ngx-translate/core";

// Enums
import {
  customer_category as CATEGORY,
  user_type as USER,
} from "src/app/core/utilities/shared_enums";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  // #region declare variables
  @Output() mobileMenuButtonClicked = new EventEmitter();
  MCBranches = MCBranches;
  USER = USER;

  mc_logo = `../../../assets/app-assets/logo/mc${MCBranches.MC}_logo.png`;
  customer_category_name: string = "";
  customer_category: number;
  userType: string;

  // #endregion

  // #region constructor

  constructor(
    public securityService: SecurityService,
    public translate: TranslateService
  ) {
    this.customer_category =
      this.securityService.retrieveUser().customer_category;
    this.userType = this.securityService.retrieveUser().user_type;

    if (this.userType == USER.FLOUR)
      this.customer_category_name =
        this.customer_category == CATEGORY.DIRECT
          ? CATEGORY.direct
          : this.customer_category == CATEGORY.DISTRIBUTER
          ? CATEGORY.distributer
          : this.customer_category == CATEGORY.MULTI
          ? CATEGORY.multi
          : CATEGORY.beneficiary;
  }

  // #endregion

  // #region ngOnInit

  ngOnInit() {}

  // #endregion

  // #region main actions

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.securityService.logout();
  }

  // #endregion
}
