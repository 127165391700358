// The base class for all customer requests
abstract class CustomerRequestDto {}

export class joinRequestDto {
  name!: string;
  tradeName!: string;
  id_number!: number;
  unifiedNumber!: number;
  activity_type!: any;
  region!: number;
  branch!: string;
  city!: any;
  address!: string;
  district!: any;
  mobile!: string;
  phone!: number;
  postal_code!: number;
  fax!: number;
  street!: string;
  customer_category!: string;
  distributer!: any;
  internal_customer_type!: string;
  request_type!: string;
  id!: number;
  commercial_registration_number!: any;
  commercial_registration_expiry_date!: any;
  commercial_register_copy!: any;
  city_license_number!: any;
  industrial_registration_number!: any;
  city_license_expiration_date!: any;
  industrial_registration_expiry_date!: any;
  city_license_copy!: any;
  industrial_registration_copy!: any;
  city_license_source!: any;
  is_taxable!: boolean;
  email!: string;
  responsible_name!: string;
  responsible_position!: string;
  sap_code!: number;
  sap_subcode!: string;
  tax_registeration_number!: string;
  customer_number!: number;
  sago_customer_id!: number;
  sap_customer_id!: number;
  products!: ProductDto[];

  constructor() {
    this.products = [];
  }
}

export class IncreaseQuantityCustomerRequest {
  request_type!: string;
  current_customer!: number;
  products!: ProductDto[];
}

export class TemporaryQuantityCustomerRequest {
  current_customer: number;
  request_type: string;
  products: ProductDto[];
}

export class CustomerDto {
  form_number: number; // need to be revised!

  customer_storage_warehouse_cleanliness!: any;
  technical_visit_report_file!: string;
  shop_workers_health_licenses!: any;
  shop_equipement_cleanliness!: any;
  total_asked_unpacked_amount!: any;
  shop_equipement_report!: string;
  total_asked_packed_amount!: any;
  committee_form_file!: string;
  total_unpacked_amount!: any;
  shop_owner_notes!: string;
  floor_cleanliness!: any;
  total_packed_amount!: any;
  actual_visit_date!: any;

  approved_customer_products!: ProductDto[];
  products!: ProductDto[];

  industrial_registration_expiry_date: any;
  industrial_registration_number!: any;
  industrial_registration_copy?: any;

  commercial_registration_expiry_date!: any;
  commercial_registration_number!: any;
  commercial_register_copy!: any;

  city_license_expiration_date!: any;
  city_license_source!: any;
  city_license_number!: any;
  city_license_copy!: any;

  distributer_sago_customer_id: number;
  distributer_sap_customer_id: number;
  distributer_name: string;
  old_distributor: string;
  distributer: any;

  customer_commercial_register_copy_sent_to_sago!: boolean;
  customer_city_license_copy_sent_to_sago!: boolean;
  customer_sent_to_sago!: boolean;
  internal_customer_type?: string;
  customer_category_name!: string;
  customer_number!: string;
  customer_category!: any;
  current_customer!: any;
  unifiedNumber!: string;
  customer!: number;

  request_status_name!: string;
  request_status_code!: string;
  request_status!: number;

  rejected_by_name!: string;
  reject_reason!: string;
  reason: string;

  sago_customer_id!: string;
  sap_subcode_name: number;
  sap_customer_id!: string;
  sago_record_id?: number;
  sap_code_name: number;
  sago_status!: string;
  sap_subcode!: any;
  sap_code!: any;

  tax_registeration_number!: string;
  activity_type_name!: string;
  is_taxable!: boolean;
  activity_type!: any;

  building_number!: number;
  district_name!: string;
  region_name!: string;
  branch_name!: string;
  city_name!: string;
  postal_code!: any;
  street!: string;
  district!: any;
  address!: any;
  region!: any;
  city!: any;

  tradeName!: string;
  id_number!: any;
  email!: string;
  notes!: string;
  name!: string;
  mobile!: any;
  branch!: any;
  pledge!: any;
  phone!: any;
  user!: any;
  fax!: any;
  id!: any;
}

export class customerFormDto {
  request_type: string;
  form_id!: number;
  req_id!: number;

  actual_visit_date: string;
  shop_equipement_report: string;
  shop_owner_notes: string;

  committee_form_file: File;
  technical_visit_report_file: File;
  products!: ProductDto[];
}

export interface customer_category {
  name: string;
  id: number;
}

export class ProductDto {
  committee_form_member_amount!: number;
  branch_manager_comments!: string;
  is_consumed_identical!: Boolean;
  asked_unpacked_amount!: number;
  asked_packed_amount!: number;
  received_amount!: number;
  is_fair_amount!: Boolean;
  current_amount!: number;
  product_name!: string;
  packed_amount!: number;
  unpacked_amount!: any;
  weight_type: any;
  askedAmount: number;
  product!: number;
  amount!: number;
  code!: number;
  id!: number;
  type: string;
  new_val: number;
  beneficiary!: number;
  beneficiary_name!: string;
  approved_customer!: number;
  approved_customer_name!: string;
  reason?: string;
  title!: string;
  deleted?: boolean;
  filtering?: boolean;
  inValid?: boolean;

  date_from: string | number | Date;
  date_to: string | number | Date;
  is_addition: any;
}
