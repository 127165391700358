<div class="dropdown d-inline-block mx-3" ngbDropdown>
  <button
    type="button"
    class="btn btn-primary-outlined border bg-light my-1 fw-bolder"
    id="page-header-user-dropdown"
    ngbDropdownToggle
  >
    <span *ngIf="flagvalue !== undefined">{{ flagvalue }}</span>
    <span *ngIf="flagvalue === undefined">{{ valueset }}</span>
    <span></span>
  </button>
  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
    <a
      href="javascript:void(0);"
      class="dropdown-item text-center"
      *ngFor="let item of listLang"
      (click)="setLanguage(item.name, item.lang)"
      [ngClass]="{ active: active === item.lang }"
    >
      <span class="">{{ item.name }}</span>
    </a>
  </div>
</div>
