export const CUSTOMER_APIS = {
  JOIN_REQUEST_CREATE: "customer/create",
  JOIN_REQUEST_PRODUCTS_CREATE: "customer/customer-products/create/",
  JOIN_REQUEST_SINGLE: "customer/get/",
  JOIN_REQUEST_UPDATE: "customer/update/",
  JOIN_REQUEST_DELETE: "customer/requests/delete",

  JOIN_FORM_UPDATE: "customer/committee-form/update/",
  JOIN_FORM_PRODUCTS_UPDATE: "customer/committee-form/products/update/",
  JOIN_FORM_PRODUCTS_CREATE: "customer/committee-form/products/create/",
  JOIN_FORM_PRODUCTS_SINGLE: "customer/committee-products/get/", // not clear
  JOIN_FORM_SINGLE: "customer/committee-form/get/",
  FORM_APPROVAL_SINGLE: "customer/committee-approval/customer/get/", // not clear
  FORM_APPROVAL_ACTION: "customer/committee-approval/approve",
  FORM_STATUS_SINGLE: "customer/committee-approval/get/customer/", // not clear

  APPROVED_SINGLE: "customer/approved/get/",
  APPROVED_PRODUCTS_SINGLE: "customer/approved/products/list/",
  SEARCH: "customer/search",

  APPROVED_BENEFICIARY_UPDATE: "customer/approved/beneficiaries/update/", // not clear
  APPROVED_BENEFICIARY_SINGLE: "customer/approved/beneficiaries/get/", // not clear
  APPROVED_BENEFICIARY_LIST: "customer/approved/beneficiaries/list", // not clear
  BENEFICIARY_DELETE: "customer/delete-customer", // not clear

  INCREASE_REQUEST_CREATE: "customer/increase/create",
  TEMP_INCREASE_REQUEST_CREATE: "customer/create-temporary-request",
  TEMP_INCREASE_REQUEST_DATA: "customer/list-temporary-request-products/",
  TEMP_INCREASE_REQUEST_LIST: "customer/list-temporary-requests",

  CHANGE_INFO_REQUEST_CREATE: "customer/customer-change-info",
  CHANGE_INFO_REQUEST_DATA: "customer/customer-change-info/list/",
  CHANGE_INFO_ATTACH_REQUEST_CREATE: "customer/customer-change-info/attachment",
  CHANGE_INFO_ATTACH_REQUEST_DATA:
    "customer/customer-change-info/attachment/list/",
  CHANGE_INFO_REQUEST_CREATE_FINAL: "customer/create-customer-request", // will be removed
  CHANGE_INFO_REQUEST_DELETE: "customer/delete-change-info/", // will be removed

  EDIT_INFO_REQUEST_CREATE: "customer/customer-change-info",
  EDIT_INFO_REQUEST_UPDATE: "customer/customer-change-info/update",
  EDIT_INFO_REQUEST_VIEW: "customer/customer-change-info/get-for-update/",
  EDIT_INFO_ATTACH_UPDATE: "customer/customer-change-info/attachment/update",
  EDIT_INFO_ATTACH_DELETE: "customer/customer-change-info/attachment/delete",

  EDIT_PRODUCTS_REQUEST_CREATE: "customer/customer-update-product",
  EDIT_PRODUCTS_ATTACH_REQUEST_CREATE:
    "customer/customer-update-product/attachment",
  EDIT_PRODUCTS_REQUEST_DATA: "customer/customer-update-product/list/",
  EDIT_PRODUCTS_ATTACH_REQUEST_DATA:
    "customer/customer-update-product/attachment/list/",
  EDIT_PRODUCTS_REQUEST_CREATE_FINAL:
    "customer/create-customer-update-product-request", // will be removed
  EDIT_PRODUCTS_REQUEST_DELETE: "customer/delete-update-product/", // will be removed

  BRANCH_CHANGE_REQUEST_CREATE: "customer/create-transfer-to-branch",
  BRANCH_CHANGE_REQUEST_DATA: "customer/get-transfer-to-branch-requests/",

  CATEGORY_CHANGE_REQUEST: "customer/customer-change-category",
  CATEGORY_CHANGE_ATTACH_REQUEST:
    "customer/customer-change-category/attachment",
  CATEGOTY_CHANGE_REQUEST_DATA: "customer/customer-change-category/list/",
  CATEGORY_CHANGE_ATTACH_REQUEST_DATA:
    "customer/customer-change-category/attachment/list/",

  COMPANY_TRANSFER_REQUEST_CREATE: "customer/create-transfer",

  DISTRIBUTER_CHANGE_REQUEST_CREATE:
    "customer/create-transfer-to-another-distributor",
  DISTRIBUTER_CHANGE_ATTACH_REQUEST_CREATE:
    "customer/request-attachment/create", // not clear
  DISTRIBUTER_CHANGE_ATTACH_REQUEST_DATA: "customer/request-attachment/list/", // not clear
  DISTRIBUTER_PRODUCTS_SINGLE: "customer/approved/distributer/products/",
  DISTRIBUTER_LIST: "customer/approved/distributer/list",

  SAP_NUMBER_UPDATE: "customer/changing-sap-number/",
  CONFIG_LIST: "customer/configs/list",
  CONFIG_UPDATE: "customer/configs/update/",
  ADJUSTMENTS: "customer/ending-adjustments/",

  EXCEL_FLOUR_UPLOAD: "customer/approved/import/customers",
  EXCEL_FEED_UPLOAD: "customer/uploading-list-of-feed-customers",
  EXCEL_FLOUR_PRODUCTS_UPLOAD: "customer/approved/products/import",
  EXCEL_FEED_PRODUCTS_UPLOAD:
    "customer/uploading-list-of-feed-customers-products",
  EXCEL_BENEFICIARY_UPLOAD: "customer/approved/import/beneficiary",
  EXCEL_FLOUR_DOWNLOAD: "customer/approved/flour${count}/export",
  EXCEL_FLOUR_PRODUCTS_DOWNLOAD: "customer/approved/products/export",

  SAP_INTEGRATION_HISTORY: "customer/sap-log/list",
  SAP_INTEGRATION_VIEW: "customer/sap-log/get/",
  SAP_INTEGRATION_REQ_RESEND: "",
} as const;
