import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-products-history-display",
  templateUrl: "./products-history-display.component.html",
  styleUrls: ["./products-history-display.component.scss"],
})
export class ProductsHistoryDisplayComponent {
  tableData;
  tableHeader = [
    { key: "old_amount", value: "OLD_VALUE" },
    { key: "new_amount", value: "NEW_VALUE" },
    { key: "created", value: "REQ_DATE" },
    { key: "request_sago_record_id", value: "REQ_SAGO_NUM" },
    { key: "request_type_ar", value: "REQ_TYPE" },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.tableData = data.api_result;
  }
}
