// Angular modules
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";

import {
  Table_Actions as actions,
  tablePaginationDto,
} from "src/app/shared/components-UI/advancedtable/advanced.model";
import { COMPANY_APIS } from "src/app/core/apis/company.api";
import { MatDialog } from "@angular/material/dialog";
import { FilterModelDto } from "src/app/pages/company/company.utility";

// Components
import { AssignJoinRequestComponent } from "src/app/shared/components-reusable/assign-request-committee/assign-join-request.component";

// Services
import { SecurityService } from "src/app/core/services/security.service";
import { ApiService } from "src/app/core/services/api.service";
import { TranslateService } from "@ngx-translate/core";

// 3rd-Party tools
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-increase-amount-request-list",
  templateUrl: "./increase-amount-request-list.component.html",
  styleUrls: ["./increase-amount-request-list.component.scss"],
})
export class IncreaseAmountRequestListComponent implements OnInit {
  // #region varaibles
  userType: string;
  title: string;

  hasData$: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  // models
  filterModel: FilterModelDto;

  // requests table:
  tableDataLength: number;
  tableData: any[] = [];
  tableHeaders: any[];
  tableActions: any[];

  // Table actions:
  salesManagerActions = [actions.assign_committee_member, actions.review_form];
  committeeActions = [actions.update_form, actions.review_form];
  adminActions = [actions.review_form];

  // #endregion

  // #Region Constructor

  constructor(
    private securityService: SecurityService,
    private translation: TranslateService,
    private titleService: Title,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private API: ApiService,
    private router: Router
  ) {
    // #region init-variables

    this.userType = this.securityService.retrieveUser().user_type;
    this.filterModel = new FilterModelDto();
    this.title = "INCREASE_REQ";
    this.titleService.setTitle(`${this.translation.instant(this.title)}`);

    this.tableHeaders = [
      { key: "request_number", value: "REQ_NUM" },
      { key: "customer_name", value: "CLIENT_NAME" },
      { key: "customer_category_name", value: "CLIENT_CATEGORY" },
      { key: "assigned_to_name", value: "COMMITTEE" },
      { key: "request_status_name", value: "REQ_STATUS" },
      { key: "created", value: "JOIN_DATE" },
    ];

    // #endregion

    // #region init-functions

    this.setUserActions();

    // #endregion
  }

  setUserActions() {
    if (this.userType == "committee") this.tableActions = this.committeeActions;
    else if (this.userType == "sales")
      this.tableActions = this.salesManagerActions;
    else this.tableActions = this.adminActions;
  }

  // #Region OnInit

  ngOnInit(): void {
    this.loadControls();
  }

  // #endregion

  // #region filter section

  newFilterModel(model: FilterModelDto) {
    this.filterModel = model;
    this.getListOfRequestsByType("increase_flour");
  }

  // #endregion

  // #region loadControls

  loadControls() {
    this.getListOfRequestsByType("increase_flour");
  }

  getListOfRequestsByType(type: string, pageIndex?: number, pageSize?: number) {
    this.API.getDataFromServer(COMPANY_APIS.REQUEST_LIST_BY_TYPE + type, {
      page: pageIndex || 1,
      size: pageSize || 20,
    })
      .pipe(
        map((response) => {
          return {
            count: response.count,
            data: response.data.map((line) => {
              return {
                ...line,
                created: this.datePipe.transform(line.created, "yyyy-MM-dd"),
              };
            }),
          };
        })
      )
      .subscribe((res) => {
        this.tableDataLength = res.count;
        this.tableData = res.data;
        if (this.tableData.length > 0) this.hasData$.next(true);
        else this.hasData$.next(false);
      });
  }

  tablePageChange(pagination: tablePaginationDto) {
    this.getListOfRequestsByType(
      "increase_flour",
      pagination.pageIndex,
      pagination.pageSize
    );
  }

  // #region table-actions

  onSelectedAction(line: any) {
    const { id, request_number, customer_number, assigned_to } = line.model;
    if (line.action == actions.assign_committee_member) {
      this.assignmentToCommitteeModal(
        id,
        request_number,
        customer_number,
        assigned_to
      );
    }
    if (line.action == actions.update_form)
      this.router.navigate([
        `pages/customer/increase-amount/details/flour/${line.model.id}/${line.model.form_id}/${line.model.assigned_to_id}/${line.model.current_customer}/${line.model.request_status}`,
      ]);

    if (line.action == actions.review_form) {
      this.router.navigate([
        `pages/customer/increase-amount/approval/flour/${line.model.id}/${line.model.form_id}/${line.model.current_customer}/${line.model.request_status}`,
      ]);
    }
  }

  assignmentToCommitteeModal(
    id: number,
    form_id: number,
    customer_id: number,
    assigned_to_id: number
  ) {
    const dialogRef = this.dialog.open(AssignJoinRequestComponent, {
      data: { id, form_id, customer_id, assigned_to_id },
      direction: "rtl",
      width: "150vh",
      height: "70vh",
    });
    dialogRef
      .afterClosed()
      .subscribe(() => this.getListOfRequestsByType("increase_flour"));
  }

  // #endregion
}
