import { Component } from "@angular/core";

import { LanguageService } from "src/app/core/services/language.service";

@Component({
  selector: "app-languages-menu",
  templateUrl: "./languages-menu.component.html",
  styleUrls: ["./languages-menu.component.scss"],
})
export class LanguagesMenuComponent {
  flagvalue;
  valueset = "العربية";
  active = "ar";

  listLang = [
    {
      name: "العربية",
      lang: "ar",
    },
    {
      name: "English",
      lang: "en",
    },
  ];

  constructor(private languageService: LanguageService) {}

  setLanguage(text: string, lang: string) {
    this.flagvalue = text;
    this.languageService.setLanguage(lang);
    this.active = lang;
  }
}
