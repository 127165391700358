<mat-accordion class="px-3">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> {{ "SEARCH_BY" | translate }} : </mat-panel-title>
    </mat-expansion-panel-header>
    <form id="search" (ngSubmit)="emitModelData(model)">
      <div class="row justify-content-start">
        <span class="row justify-content-start mx-0">
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label>{{ "CLIENT_NAME" | translate }}</mat-label>
            <input [(ngModel)]="model.name" [ngModelOptions]="{ standalone: true }" type="text" matInput />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label>{{ "REQ_NUM" | translate }}</mat-label>
            <input [(ngModel)]="model.request_number" [ngModelOptions]="{ standalone: true }" type="text" matInput />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3" *ngIf="!isFeed">
            <mat-label>{{ "CLIENT_CATEGORY" | translate }}</mat-label>
            <mat-select [(ngModel)]="model.customer_category" [ngModelOptions]="{ standalone: true }">
              <mat-option value="">{{ "ALL" | translate }}</mat-option>
              <mat-option *ngFor="let customer of allCustomers" [value]="customer.id">
                {{ customer.name | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label>{{ "CHOOSE_BRANCH" | translate }}</mat-label>
            <mat-select [(ngModel)]="model.branch" [ngModelOptions]="{ standalone: true }">
              <mat-option value="">{{ "ALL" | translate }}</mat-option>
              <mat-option *ngFor="let branch of allBranches" [value]="branch.code">
                {{ branch.name_ar }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="isGeneral" appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label>{{ "CHOOSE_REQ_TYPE" | translate }}</mat-label>
            <mat-select [(ngModel)]="model.request_type" [ngModelOptions]="{ standalone: true }">
              <mat-option value="">{{ "ALL" | translate }}</mat-option>
              <mat-option *ngFor="let request of allRequestTypes" [value]="request.type">
                {{ request.name | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span *ngIf="!isRequest" class="row justify-content-start mx-0">
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label> {{ "PORTAL_NUM" | translate }} </mat-label>
            <input [(ngModel)]="model.customer_number" [ngModelOptions]="{ standalone: true }" type="text" matInput />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3" *ngIf="!isFeed">
            <mat-label>{{ "SAGO_NUM" | translate }}</mat-label>
            <input [(ngModel)]="model.sago_customer_id" [ngModelOptions]="{ standalone: true }" type="text" matInput />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label>{{ "SAP_NUM" | translate }}</mat-label>
            <input [(ngModel)]="model.sap_customer_id" [ngModelOptions]="{ standalone: true }" type="text" matInput />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-sm-6 col-md-4 col-lg-3">
            <mat-label>{{ "CLIENT_STATUS" | translate }}</mat-label>
            <mat-select [(ngModel)]="model.is_deleted" [ngModelOptions]="{ standalone: true }">
              <mat-option value="false">
                {{ "ACTIVE" | translate }}
              </mat-option>
              <mat-option value="true">
                {{ "INACTIVE" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <!-- <span *ngIf="!isRequest" class="mb-4">
          <h3>{{ "SORT_BY" | translate }}</h3>
          <mat-radio-group
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="model.order_by"
          >
            <ng-container *ngFor="let option of flourRadioGroup">
              <mat-radio-button
                class="mx-3 d-block d-md-inline-block"
                color="primary"
                [value]="option.value"
                >{{ option.name | translate }}
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </span> -->
      </div>
    </form>
    <button form="search" class="d-block btn btn-secondary col-8 col-md-2 ms-auto me-2">
      {{ "SEARCH" | translate }}
    </button>
  </mat-expansion-panel>
</mat-accordion>