// #region imports

// angular modules
import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// services
import { Table_Actions } from "src/app/shared/components-UI/advancedtable/advanced.model";

// #endregion
@Component({
  selector: "app-join-form-list",
  templateUrl: "./join-form-list.component.html",
  styleUrls: ["./join-form-list.component.scss"],
})
export class JoinFormListComponent implements OnInit {
  // // #region declare variables
  // title = 'JOIN_FORMS'
  // listOfHeaderTitle: any[];
  // listOfItems: any[];
  // actions: any[];
  // // model: ;
  // lengthOfData: number;
  // // this property for remembering the table page size after deleting
  // tableEvent: any;
  // // #endregion
  // // #region constructor
  // constructor(
  //   public dialog: MatDialog,
  //   private router: Router,
  // ) {
  //   // init variables
  //   // init variables
  //   this.listOfItems = [];
  //   this.listOfHeaderTitle = [
  //     { key: "request_number", value: "REQ_NUM" },
  //     { key: "customer_name", value: "CLIENT_NAME" },
  //     { key: "assigned_to_name", value: "ASSIGNEES" },
  //     { key: "request_status_name", value: "REQ_STATUS" },
  //     { key: "created", value: "JOIN_DATE" },
  //   ];
  //   this.actions = [Table_Actions.review_request, Table_Actions.review_form];
  // }
  // // #endregion
  // // #region ngOnInit

  ngOnInit(): void {
    // this.loadControls();
  }

  // #endregion

  // #region load controls

  // loadControls() {
  //   // this.getListOfFlourRequests()
  // }

  // #endregion

  // #region main actions

  // getListOfFlourRequests( pagination?: any ){
  //   this.companyService.getListOfFlourRequests( 'new', pagination?.pageIndex,pagination?.pageSize ).pipe(
  //     map((res: any) => res.data ),
  //     map((data) => data.map((line: any ) =>
  //       { return { ...line, created: this.datePipe.transform( line.created, 'yyyy-MM-dd')} }
  //     ))).subscribe(res => console.log(res))
  // }

  // onAction(event: any) {
  //   if (event.action == Table_Actions.review_form) {
  //     this.router.navigate([
  //       `/pages/company/join-form/details/flour/${event.model.id}`,
  //     ]);
  //   } else if (event.action == Table_Actions.review_request) {
  //     this.router.navigate([
  //       `/pages/company/join-request/details/flour/${event.model.id}`,
  //     ]);
  //   }
  // }

  // tablePageChange(event: any) {
  //   this.tableEvent = event;
  //   // get the new list
  //   // this.getListOfFlour(event);
  // }

  // #endregion
}
