export const ADMIN_APIS = {
  USER_LIST: "user/staff/list",
  USER_SINGLE: "user/staff/get/",
  USER_CREATE: "user/staff/create",
  USER_UPDATE: "user/staff/update/",
  USER_DELETE: "user/staff/delete/",
  USER_CHANGE_PASSWORD: "user/change-user-password/",
  ACTIVITY_PRODUCTS_UPDATE: "lookup/activity/product/create",
  USER_INTERNAL_DATA: "customer/approved/get-for-update/",
  USER_INTERNAL_DATA_UPDATE: "customer/approved/update/",
  PRODUCTS_HISTORY_REPORT: "customer/products/history",
  PRODUCTS_HISTORY_REPORT_EXCEL: "customer/products/history/export",
  TEMP_AMOUNT_HISTORY_REPORT: "customer/temp-products/history",
  TEMP_AMOUNT_HISTORY_REPORT_EXCEL: "customer/temp-products/history/export",
  ACTIVATE_CLIENT: "customer/mark-not-deleted/",
} as const;
